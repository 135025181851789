var rex_php_tags = new RegExp("<\\?php(?:[\\n\\r]|.)+?\\?>(?!\"|'')", "gim");
var rex_script_tags = new RegExp("<script(?:[\\n\\r]|.)+?\\/script>", "gim");
var rex_comments_tags = new RegExp("<!--(?:[\\n\\r]|.)+?-->", "gim");
// Récupération de la valeur de la constante NO_LAZYLOAD pour appliquer le paramètre aux swiper
var isLazyLoad = (typeof is_lazy_load !== 'undefined' && is_lazy_load==false) ? false : true;

// Strips php contents /!\ THIS DELETES PHP TAGS AND EVERYTHING IN BETWEEN /!\
function stripPhpContents(str) {
    return str.replace(rex_php_tags, "");
}

// Strips js contents /!\ THIS DELETES SCRIPT TAGS AND EVERYTHING IN BETWEEN /!\
function stripJSContents(str) {
    return str.replace(rex_script_tags, "");
}

// Strips html comments /!\ THIS DELETES COMMENTS TAGS AND EVERYTHING IN BETWEEN /!\
function stripHTMLComments(str) {
    return str.replace(rex_comments_tags, "");
}

function stripCombo(str) {
    var output = stripHTMLComments(str);

    output = stripJSContents(output);
    output = stripPhpContents(output);

    return output;
}

var elmt;
var loader;
var prev_display;
// Shows button's underlying loader and triggers defined action
function waitingProcess(loader, action, id_form) {
    var elmt = $(this),
        loader = $(loader);

    prev_display = elmt.css("display");

    if (!loader.parent().hasClass("loading")) {
        loader.parent().addClass("loading");

        switch (action) {
            case 0:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabAuthTop,
                    1,
                    path_relative_root
                );
                break;

            case 1:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabLostPwdTop,
                    1,
                    path_relative_root
                );
                break;

            case 2:
                window.setTimeout(ajaxDeconnect, 1000, path_relative_root);
                break;

            case 3:
                window.setTimeout(function() {
                    checkAllForm(tabError, 0, path_relative_root, id_form);
                    loader.parent().removeClass("loading");
                }, 1000); // attention ne pas modifier le temps 1000 pour que le intelinput ai le temps de bien remplir les données
                //window.setTimeout(checkAllForm, 1000, tabError, 0, path_relative_root, id_form);
                break;

            case 4:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabAuth,
                    1,
                    path_relative_root
                );
                break;

            case 5:
                window.setTimeout(
                    ajax_checkAllForm,
                    1000,
                    tabLostPwd,
                    1,
                    path_relative_root
                );
                break;

            case 6:
                window.setTimeout(checkFormParrainage, 1000);
                break;

            case 7:
                if (
                    checkAllForm(
                        tabErrorGrattez,
                        0,
                        path_relative_root,
                        id_form
                    )
                ) {
                    window.setTimeout(processGrattezGagnez(), 1000);
                }
                break;
        }
    }
}

function hideLoader() {
    if (loader) {
        loader.css("display", "none");
        loader
            .removeAttr("style")
            .parent()
            .removeClass("loading");
    }
    if (elmt) {
        elmt.css("display", prev_display);
    }
}

// Alters item quantity in cart
function changeQte(type) {

    var types = [ 'plus', 'less' ],
        item, loader, qtty, ajax_url;

    if (types.includes(type)) {

        item = this.getValue('item_id', parseFloat);
        loader = document.getElementById('qte_loader_' + item);
        qtty = this.getValue('selected_qte', parseFloat);

        this.qtty_select_wrap.style.display = 'none';
        loader.style.display = 'initial';

        switch (type) {
            case 'plus':
                qtty += 1;
                break;
            case 'less':
                qtty -= 1;
                break;
        }

        $.ajax({
            url : path_relative_root + create_link('order_basket'),
            type : 'post',
            data : {
                action : 'del',
                panierId : item,
                cad  : 'non',
                pointcad : 0,
                qteProd : qtty,
                change_qte : true
            },
            cache : false,
            success : function (res) {

                try {
                    res = JSON.parse(res);
                } catch (e) {
                    console.error('JSON ERROR: could not parse data');
                    return false;
                }

                res = $('<div />').html(res.datas);

                document.getElementById('main_cart_wrapper').innerHTML = $('#main_cart_wrapper', res).html(); 
                if (wShop.$refs.wCouponForm) {
                    wShop.$refs.wCouponForm.reloadBloc+=1;
                }

                //update top cart
                var response = ajax_file("ajax_show_basket.php");
                document.getElementById('cart_top').innerHTML = response;

                cart = translate('mon_panier');
                var total = $('#cart_top .cart').attr('data-cart'),
                    text;

                if(total <= 1){
                    text = translate('one_product');
                } else {
                    text = translate('2_product');
                };

                $('.cart_main_title.basket').html(cart +' '+ '<span class="nb_products">(' + total + ' ' + text + ')</span>');

                if (dataLayer !== undefined ) {
                    dataLayer.push({
                        'event': 'basketUpdate',

                    });
                }

                // Reinit swiper on panier product quantity change
                if ($('.elem_tunnel .prod_reassurance_swiper').length) {
                    setTimeout(
                        new Swiper('.elem_tunnel .prod_reassurance_swiper', {
                            // slidesPerView: 2.5,
                            slidesPerView: 'auto',
                            loop: true,
                            speed: 4000,
                            freeMode: true,
                            autoplay: {
                                delay: 1,
                                disableOnInteraction: false,
                            },
                        }),500
                    );
                }
            }
        });
    }
}

setTimeout(() => {
    $("#pageProduit .product_images .eclat_rect").css('opacity', '1');
}, 250);

// FP MAIN SWIPER
function mainSwiperFp() {
    if ($(".main_fp_swiper .swiper-slide").length > 2) {
        var main_fp_swiper = new Swiper('.main_fp_swiper', {
            slidesPerView: 2,
            spaceBetween: 12,
            navigation: {
                nextEl: '.main_fp_next',
                prevEl: '.main_fp_prev',
            }
        });
        
    } else if ($(".main_fp_swiper .swiper-slide").length == 1) {
        $(".main_fp_swiper").addClass("no_swiper only_one");
        $("#pageProduit .product_images").addClass("only_one");
        
    } else {
        $(".main_fp_swiper").addClass("no_swiper");
        $("#pageProduit .product_images").removeClass("only_one");
    }
    
    setTimeout(() => {
        $("#pageProduit .product_images .eclat_rect").css('opacity', '1');
    }, 250);
}

// Reload product from ref
function loadProduct(new_id, type, page, itm_rr_id) {
    var form = this;
    var old_id = this.getValue('produit_id');
    var productRef = this.getValue('produit_ref');
    var col_id = this.getValue(this.getValue('idCouleur'));
    var sze_id = this.getValue(this.getValue('idTaille'));
    var act = this.getValue('act');
    var qte_itm = 1;

    var search = form.getValue('isSearch');

    if (this.elements.namedItem('qteProd')) {
        var qte_itm = this.elements.namedItem('qteProd').value;
    }

    var is_model = this.getValue('hdn_is_model');
    var is_wishlist = $('#wishlist').length > 0;
    var is_achat_express_v1 = $('.express_response').length > 0;
    var is_achat_express = $('#is_achat_express_v2').val() == 1;
    var is_categ_super = this.getValue('isCategSuper') != undefined ? this.getValue('isCategSuper') : 0;

    var cpt_prod = itm_rr_id !== '' ? itm_rr_id.replace('_', '') : '';
    var data = {
        produit_id : old_id,
        produit_ref : productRef,
        produit_principal : old_id,
        couleurProd : col_id,
        tailleProd : sze_id,
        act : act,
        qteProd : qte_itm,
        hdn_product_id : old_id,
        hdn_is_model : is_model,
        produit_id_new : new_id,
        type : type,
        type_page : (page !== undefined && page !== '' ) ? page : 'product',
        is_achat_express : (is_wishlist || is_achat_express_v1) ? 1 : 0,
        is_achat_express_v2 : is_achat_express && !is_wishlist ? 1 : 0,
        cpt_prod : cpt_prod-1,
        search : (search ? true : false),
        isCategSuper : is_categ_super
    };

    if (is_wishlist) {
        data.wishlist = true;
    }

    $.ajax({
        type : 'post',
        url : path_relative_root + create_link("ajax_get_product_regroup_ref"),
        data : data,
        success : function (res) {
            try {
                res = $.parseJSON(res);
            } catch (e) {
                console.error('parseJSON');
                return;
            }

            if (res.success == 'ok') {
                var datas = res.datas;
                if (data.type_page == 'product') {
                    // Main HTML
                    var $html_main = $(stripCombo(datas.html_main));
                    
                    //Save ariane to prevent delete
                    var saveAriane = $('.product_main_wrapper #breadcrumbs');

                    // HTML Photo et informations
                    var main_wrapper = $('.product_main_wrapper', $html_main);

                    // HTML Associated products
                    var product_associations = $('.associations_section', $html_main);

                    // HTML CMS module
                    var product_cms_bloc = $('#product_cms_bloc', $html_main);

                    var zoom_wrapper = $('.zoom_main_wrapper', $html_main);
                    var feats_wrapper = $('.product_feats_wrapper', $html_main);
                    if (data.is_achat_express_v2) {
                        // Photo et information
                        var itm_id = old_id + (itm_rr_id !== '' ? itm_rr_id.replace('_','-') : '');
                        $('#itm-' + itm_id).replaceWith($html_main);
                        $('input.color_' + new_id + itm_rr_id).prop('checked', false);
                        $('input#color_' + new_id + '_' + data.couleurProd + itm_rr_id).prop('checked', true);
                    } else if (is_wishlist || is_achat_express_v1) {
                        // Photo et information
                        $('.product_main_wrapper', '#lightbox_achat_express').html(main_wrapper.html());
                        // Description compo, livraison, guide tailles
                        $('.product_feats_wrapper', '#lightbox_achat_express').html(feats_wrapper.html());

                        if (!$(".w-alertestock-form").length) {
                            new Vue({components:{wAlertestockForm: wAlertestockForm}}).$mount('#keep_alive_stock');
                        }
                    } else {
                        // Update HTML Photo et informations
                        $('.product_main_wrapper', '#site_global_wrap').html(main_wrapper.html());

                        //Replace ariane by the previous one
                        $('#breadcrumbs').replaceWith(saveAriane);

                        // Update HTML Associated products
                        $('.associations_section', '#site_global_wrap').html(product_associations.html());

                        // Update HTML CMS module
                        $('#product_cms_bloc', '#site_global_wrap').html(product_cms_bloc.html());

                        // Zoombox
                        $('.zoom_main_wrapper', '#site_global_wrap').html(zoom_wrapper.html());

                        // Description compo, livraison, guide tailles
                        $('.product_feats_wrapper', '#site_global_wrap').html(feats_wrapper.html());

                        $('input.color').prop('checked', false);
                        $('input#color_' + data.couleurProd).prop('checked', true);

                        /*FP sliders*/
                        if ($("#visual_scroller_wrapper").length) {
                            var productThumbs = new Swiper('#visual_scroller', {
                                slidesPerView: 4,
                                spaceBetween: 8,
                                direction: 'vertical',
                                navigation:
                                    {
                                        nextEl: '.thumbNext',
                                        prevEl: '.thumbPrev',
                                    },
                            });
                        }

                        // PRODUCT SWIPER
                        if ($("#jqzoom").length > 1) {
                            var main_productVisu = new Swiper('#jqzoom', {
                                direction: 'vertical',
                                loadOnTransitionStart: true,
                                thumbs: {
                                    swiper: productThumbs
                                },
                                slidesPerView: 1,
                                lazy: true,
                            });
                        }

                        if ($(".edito_swiper .swiper-slide").length > 2) {
                            edito_swiper = new Swiper(".edito_swiper", {
                                slidesPerView: 2,
                                spaceBetween: 7,
                                navigation: {
                                    nextEl: '.edito_swiper_next',
                                    prevEl: '.edito_swiper_prev',
                                },
                                breakpoints: {
                                    1190: {
                                        slidesPerView: 3,
                                        spaceBetween: 7,
                                    },
                                    1160: {
                                        slidesPerView: 2,
                                        spaceBetween: 5,
                                    },
                                },
                            });
                        }
                    }
                    accordionDropdown('#prodFeatsWrapperAccordion li .title', '.content', '#prodFeatsWrapperAccordion li');

                    // Au changement de couleur on ajoute la classe 'swiper-slide' non présente car vertical_product est appelé. 
                    setTimeout(function () {

                        var homeTgItem = $('body.homepage .tg-module #moduleSwiper .swiper-wrapper');

                        homeTgItem.children().each(function () {

                            if ($(this).hasClass('item') && !$(this).hasClass('swiper-slide')) {

                                $(this).addClass('swiper-slide');
                            } 
                        });

                    },);

                } else if (data.type_page == 'assoc') {
                    if (data.is_achat_express_v2) {
                        // Photo et information
                        var $html_main = $(stripCombo(datas.html_main));
                        var itm_id = old_id + (itm_rr_id !== '' ? itm_rr_id.replace('_','-') : '');

                        $('#itm-' + itm_id).replaceWith($html_main);
                        $('input.color_' + new_id + itm_rr_id).prop('checked', false);
                        $('input#color_' + new_id + '_' + data.couleurProd + itm_rr_id).prop('checked', true);
                    }
                }

                // On definie les tailles qui ont encore du stock
                if (!data.is_achat_express_v2) {
                    setAvailableSizes.call($('[name="itm_color"]:checked', $(form)));
                }

                if (wShop.$refs.wAlertestockForm) {
                    wShop.$refs.wAlertestockForm.reloadBloc += 1;
                }

            } else {
                $( '#btn_error' ).fadeIn();
            }

            // ASSOCIATIONS SWIPER
            if ($("#gondole_look .swiper-slide").length > 1) {
                var gondole_look = new Swiper('#gondole_look', {
                    slidesPerView: 4,
                    spaceBetween: 32,
                    breakpoints: {
                        1160: {
                        slidesPerView: 2,
                        spaceBetween: 15
                        },
                        1240: {
                        slidesPerView: 3,
                        spaceBetween: 20
                        },
                        1360: {
                        slidesPerView: 3,
                        spaceBetween: 25
                        },
                        1480: {
                        slidesPerView: 4,
                        spaceBetween: 32
                        }
                    }
                });
            }

            if ($("#gondole_aimerez").length > 1) {
                var gondoleAimerezSwiper = new Swiper('#gondole_aimerez', {
                    slidesPerView: 5,
                    spaceBetween: 32,

                    breakpoints: {
                        1160: {
                        slidesPerView: 3,
                        spaceBetween: 15
                        },
                        1240: {
                        slidesPerView: 3,
                        spaceBetween: 20
                        },
                        1360: {
                        slidesPerView: 4,
                        spaceBetween: 25
                        },
                        1480: {
                        slidesPerView: 4,
                        spaceBetween: 32
                        }
                    }
                });
            }

            if ($('#cart_slider_wrapper .swiper-slide').length > 1) {
                $('#cart_slider_wrapper').wrap( "<div class='gondole'></div>" );
                setTimeout(function() {
                    var panierSwiper = new Swiper('#cart_slider_wrapper', {
                        slidesPerView: 4,
                        spaceBetween: 15,
                        loop: false,
                        speed: 1500,
                        autoplay: {
                            delay: 3000,
                        },
                        observer: true,
                        observeParents: true,
                    });

                    $('#cart_slider_wrapper').css('opacity', '1');
                }, 800);
            }

            // FP MAIN SWIPER
            mainSwiperFp();
        },
        complete : function () {
            var heads      = $("[data-tab-head]");
            var containers = $("[data-tab-container]");

            if (window.YT_Player !== undefined) {
                window.YT_Player.destroy();
                window.YT_Player = undefined;
                window.YT_vid_id = undefined;
            }

            var activateTab = function(event) {
                event.preventDefault();

                var element        = $(this);
                var tab_number     = element.attr("data-tab");
                var head_container = element.parent();
                var container_name, container, scrollpane;

                head_container.addClass("current").siblings().removeClass("current");
                container_name = head_container.parent().attr("data-tab-head");
                container      = containers.filter("[data-tab-container='" + container_name + "']");
                scrollpane     = container.find("[data-tab]").hide().filter("[data-tab='" + tab_number + "']").show().find('.scroll-pane');

                if (scrollpane.length > 0) {
                    scrollpane.jScrollPane();
                }
            };

            setTimeout(function() {
                // Resetting alert stock field
                $('.bloc_add_alert_form').hide();
            },250)

            setTimeout(function () {
                checkSubmitState();
                eventButton();
            }, 500);

            heads.each(function(){
                var head = $(this);
                var children = head.children();
                children.removeClass("current").filter(":first").addClass("current");
                children.find('[data-tab]').click(activateTab);
            });

            $("[data-tab-container]").children('div').eq(Math.abs(1 - $('.current', "[data-tab-head]").index())).css('display', 'none');

            if (!data.is_achat_express_v2) {
                getAvailableQuantity.call($('form.product_info', '#site_global_wrap').get(0), data.produit_id_new, data.qteProd,'', data.couleurProd, itm_rr_id, is_wishlist, data.produit_ref);
                checkProductInWishlist(data.produit_id_new, data.produit_ref);
            }

            if ($('.tg-module-1 #moduleSwiper').length) {
                // iterate through all swipers with the same id
                $(".tg-module-1 #moduleSwiper").each(function (index) {
                    var $this = $(this);
                    $this.addClass("s-" + index);
                    $this.parent().find(".tgPrev").addClass("btn-prev-" + index);
                    $this.parent().find(".tgNext").addClass("btn-next-" + index);
                    var tgSwiper = new Swiper(".s-" + index, {
                        preloadImages: false,
                        lazy: true,
                        loop: false,
                        spaceBetween: 30.4,
                        slidesPerView: 5,
                        navigation: {
                            nextEl: ".btn-next-" + index,
                            prevEl: ".btn-prev-" + index,
                        }
                    });

                    // if slides < 5
                    if (tgSwiper.slides.length <= 5) {
                        // hide swiper prev & next btn
                        $this.parent().find(".btn-prev-" + index).hide();
                        $this.parent().find(".btn-next-" + index).hide();
                        // center wrapper 
                        $this.children().first().addClass('wrapper-centered');
                    }
                });
            }
        }
    });
}

// Called upon color change in product page
function onItemColorChange(form, is_regroup, itm_rr_id, from, search) {

    var control, prod_id, color_name;
    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';

    if (this.checked) {

        control = form.elements.namedItem(form.getValue('idCouleur'));
        control.value = this.value.trim();
        color_name = $(this).data("nom");
        prod_id = form.getValue('itm_id_' + control.value + itm_rr_id).trim();
        $(".chossen_color").html(color_name);

        var isAssocProduct = form.getValue('isAssocProduct') ? form.getValue('isAssocProduct') : 0;

        if (isAssocProduct == true) {
            fromPage = 'assoc';
        } else {
            fromPage = 'product';
        }

        if (is_regroup) {

            loadProduct.call(form, prod_id, 'couleur', fromPage, itm_rr_id, search);
        } else {

            // On regarde si le produti est dejà dans la wishlist
            var in_wishlist = checkProductInWishlist.call(form);
            var img = $(this).data('pic');

            changeVisu('img_large', img);

            // On definie les tailles qui ont encore du stock
            setAvailableSizes.call(this);
        }
    }
}

function moveProductFromWishlistToCart(array_wishlist_product_ids) {

    $.post(path_relative_root + create_link("ajax_add_panier_wishlist"), {data: array_wishlist_product_ids.join(",")}, function(resp) {
        if (resp == "ok") {
            document.location.href = path_relative_root + create_link('order_basket');
        }
    });
}

// Called upon size change in product page
function onItemSizeChange(form, event, itm_rr_id) {
    var control;
    var isWishList = $('#wishlist').length > 0;
    var isAchatExpressV2 = ($('#is_achat_express_v2').val() == 1);
    var productId = form.getValue('produit_id');

    var elem = isAchatExpressV2 ? '_' + productId + itm_rr_id : '';
    var productRef = form.getValue('produit_ref');
    control = form.elements.namedItem('tailleProd');
    control.value = this.value.trim();
    var from  = ($('#is_from_lookbook').val() !== undefined) ? 'lookbook' : 'product';
    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';

    if (this.checked) {
        if (window.dataLayer !== undefined) {
            window.dataLayer.push({
                'event': 'changeSize',
                'produit': {
                    'size_value': ($(this).attr('data-nom')),
                }
            });
            if (window.dataLayer[0].product) {
                window.dataLayer[0].product.size = ($(this).attr('data-nom'));
            }
        }

        if ($('#sizeerror').is(':visible')) {
            $('#sizeerror').slideUp();
        }

        // Depending on the color we see if the product is available, do this only in product page
        getAvailableQuantity($('#produit_principal' + elem).val(), $('#qteProd' + elem).val(), from, event, itm_rr_id, isWishList, productRef);

        // We check if the product is already in the wishlist
        if (!isAchatExpressV2) {
            checkProductInWishlist(productId, productRef, true);
        }
    }

    $('.productErrorFieldset').hide();

}
// Refreshes available quantity
function getAvailableQuantity(id, qty, from, event, itm_rr_id, is_wishlist) {
    itm_rr_id = (itm_rr_id !== undefined) ? itm_rr_id : '';
    var is_achat_express = $('#is_achat_express_v2').val() == 1 && !is_wishlist;
    var elem = is_achat_express ? '_' + id + itm_rr_id : '';
    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('prod_info_' + id + itm_rr_id);
    var color_id = $('#couleurProd' + elem).val();
    var size_id = $('#tailleProd' + elem).val();
    var plnb = form.getValue('produit_lot_nb');

    // Si on a pas de taille ni de couleur déjà selectionné alors on ne fait pas de vérification
    if (color_id == '' || size_id == '') {
        return;
    }

    $('#sizeerror').addClass('hided');

    var data = {
        idprod: form.getValue('produit_id'),
        idcouleur: color_id,
        idtaille: size_id,
        json: '1'
    };

    if (from == 'basket') {

        data.suff = form.getValue('produit_id');
    }

    if (plnb) {

        data.produit_lot_nb = plnb;
    }

    $.ajax({
        url: path_relative_root + create_link('ajax_liste_quantity'),
        type: 'get',
        async: false, // Should be async but would then need a loader so...
        dataType: 'json',
        data: data,
        success: function (res) {

            // Check if globale delivery country
            // OR is express checkout AND have no warehouse stock AND product not SFS
            if (res.disable_addtocart == true && ((typeof GEP != 'undefined' && GEP.isOperatedByGlobale !== undefined && GEP.isOperatedByGlobale !== false)
                || (is_achat_express && (typeof GEP  == 'undefined' && typeof res.is_sfs !== "undefined" && res.is_sfs == false)))) {
                res.stock_tampon = 0;
            }
            
            if (res.stock_tampon == 0) {
                $(".eclat_product_nouveaute_fiche_produit").show();

                $("#bloc_add_alert" + elem).show();
                $("#prod_info" + elem + " .rollover_left").hide();

                if (!is_achat_express && is_wishlist == false) {
                    $("#bloc_add_basket").hide();
                    if (!$(".w-alertestock-form").length) {
                        new Vue({components: {wAlertestockForm: wAlertestockForm}}).$mount('#keep_alive_stock');
                    }
                }

                $('#ligne_qte').append($('.restock_date'));
                $('.taunt_text').css('display', 'none');
            } else {
                if (res.stock_tampon > 0 && res.stock_tampon <= 5) {
                    if (res.stock_tampon == 1) {
                        $('.taunt_text').html(Translator.translate('last_taunt', res.stock_tampon));
                    } else {
                        $('.taunt_text').html(Translator.translate('last_taunts', res.stock_tampon));
                    }
                    $('.taunt_text').css('display', 'block');
                } else {
                    $('.taunt_text').css('display', 'none');
                }

                if (is_achat_express) {

                    if (from == 'product') {
                        // Show bloc colors when we add product to basket
                        $('#ligne_pointure' + elem).hide();
                        $('#ligne_couleur' + elem).show();
                        $("#prod_info" + elem + " .bloc_add_color").show();
                    } else if (from == 'lookbook') {
                        closeMultiShad('lightbox_achat_express_look');
                    }

                } else {
                    $(".eclat_product_nouveaute_fiche_produit").hide();
                    $("#bloc_add_alert").hide();
                    $("#bloc_add_basket").show();
                }
            }
            // Check if globale delivery country
            if (res.disable_addtocart == true) {
                if ((typeof GEP != 'undefined' && GEP.isOperatedByGlobale !== undefined && GEP.isOperatedByGlobale !== false) || (typeof GEP == 'undefined' && typeof res.is_sfs !== "undefined" && res.is_sfs == false)) {
                    $("#bloc_add_basket").hide();
                    $("#bloc_add_alert").show();
                    $("#productInfos #size_" + size_id).addClass('disabled');
                }
            }
        }
    });
}

function waitingAddToBasket(evt, form_elm, from, itm_rr_id) {

    var id = '';
    var produit_id = form_elm.getValue('produit_id');
    var produit_refext = form_elm.getValue('produit_refext');
    var suff = form_elm.getValue('suff');
    var panier_id = form_elm.getValue('panier_id');
    var titreObjet = form_elm.getValue('titreObjet');
    var idTaille = form_elm.getValue('idTaille');
    var lastTaille = form_elm.getValue('lastTaille');
    var idCouleur = form_elm.getValue('idCouleur');
    var lastCouleur = form_elm.getValue('lastCouleur');
    var toEval = form_elm.getValue('toEval');
    var page_name = form_elm.getValue('page_name');
    var path_web = form_elm.getValue('path_web');
    var enable_omniture = form_elm.getValue('enable_omniture');
    var is_not_produit = form_elm.getValue('is_not_produit');
    var is_lightbox_specifique = form_elm.getValue('is_lightbox_specifique');
    var is_achat_express = $('#is_achat_express_v2').val() == 1;
    var loader;

    if (!is_achat_express)
        evt.preventDefault();

    if (from === undefined) {

        from = '';
    }

    if (is_not_produit == true) {

        id += '_' + produit_id;
    }

    if (this.nextElementSibling && this.nextElementSibling.classList.contains('loader')) {

        loader = $(this.nextElementSibling);

        loader.addClass("loading");
    } else {

        $("#bloc_btn_active" + id).attr('class', 'cache');
        $("#bloc_btn_loader" + id).attr('class', 'f_right');
    }

    if (is_lightbox_specifique == 'true') {

        add_produit_specifique(produit_id, idCouleur, idTaille);
    } else {
        window.setTimeout(addToBasket, 1000, produit_id, suff, panier_id, titreObjet, idTaille, lastTaille, idCouleur, lastCouleur, toEval, page_name, path_web, enable_omniture, is_not_produit, from, loader, is_achat_express, itm_rr_id);
        if (window.dataLayer !== undefined && (typeof dataLayer_v2 == 'undefined' || dataLayer_v2 != 1)) {
            window.dataLayer.push({
                'event': 'addToCart',
                'ecommerce': {
                    'currencyCode': 'EUR',
                    'add': {
                        'product_ref': produit_refext
                    }
                }
            });
        }
    }
}

/*
 * Permet l'envoi du mail qui confirme l'enregistrement de sont email, pour alerter la personne quand le produit sera disponible
 */
function sendMailAlertStock(type_alert, form, itm_rr_id) {

    itm_rr_id = itm_rr_id !== undefined ? itm_rr_id : '';
    var id = form.getValue('produit_id');
    var is_achat_express = $('#is_achat_express_v2').val() == 1;
    var elem = is_achat_express ? '_' + id + itm_rr_id : '';

    var $email = $('#mail_alerte_stock' + elem).val(),
        $produit_id = $('#produit_principal' + elem).val(),
        $taille_id = $("#tailleProd" + elem).val(),
        $couleur_id = $("#couleurProd" + elem).val(),
        $type_alert = type_alert;

    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var mail_value = $('#mail_alerte_stock' + elem).val();
    $verif_mail = mail_value.match(mailformat);

    if ($email == '' || !$verif_mail) {

        $('#mail_alerte_stock' + elem).addClass('inputErr');
    } else {

        $('#mail_alerte_stock' + elem).removeClass('inputErr');
        $.ajax({
            type: 'post',
            url: path_relative_root + create_link('ajax_mail_alert_stock'),
            data: {
                email: $email,
                produit_id: $produit_id,
                taille_id: $taille_id,
                couleur_id: $couleur_id,
                type_alert: $type_alert
            },
            success: function(response) {
                $('.bloc_add_alert' + elem + ' .alert_stock').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_erreur').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_form').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_confirmation').show();
            },
            fail: function() {
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_confirmation').hide();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_erreur').show();
                $('.bloc_add_alert' + elem + ' .bloc_add_alert_form').show();
            }
        });
    }
}


function closeAlerteStock(elem) {

    // Hide bloc alert stock
    $('.bloc_add_alert_' + elem).hide();
    $("#prod_info_" + elem + " .rollover_left").show();

    // Show bloc colors and size
    $('#ligne_pointure_' + elem).hide();
    $('#ligne_couleur_' + elem).show();

    // Reset mail input
    $('.bloc_add_alert_' + elem + ' .alert_stock').show();
    $('.bloc_add_alert_' + elem + ' .bloc_add_alert_form').show();
    $('.bloc_add_alert_' + elem + ' .bloc_add_alert_confirmation').hide();
    $('.wishlistBtn').show();
    $('.form_submit.bloc_add_color').show();

    //Reset input selection
    $('#size_list_' + elem + ' input').prop('checked', false);
}

/**
  *
  * @param product_id : l'id du produit
  * @param regroup_ref_id : l'id du regroupement (si exist)
  * use in : app/views/_components/product/achat_express.php
  */
function achatExpressV2(product_id, regroup_ref_id) {
    var btn = $(this);

    // Loader
    btn.parent('.form_submit').addClass('loading');

    var elem = regroup_ref_id ? '_' + product_id + regroup_ref_id: '_' + product_id;
    
    var form = document.getElementById('prod_info' + elem);
    var size_id = form.elements['itm_size'].value;
    var tailles = $("#tailleProd" + elem);

    $("#ligne_pointure_error"+elem).remove();

    // Vérification si la taille sélectionnée n'est pas désactivée ce qui permet d'afficher l'alerte stock
    if ($('#size_'+product_id+"_"+size_id + regroup_ref_id, $('#prod_info' + elem)) != undefined && $('#size_'+product_id+"_"+size_id + regroup_ref_id, $('#prod_info' + elem)).length == 1 && $('#size_'+product_id+"_"+size_id + regroup_ref_id, $('#prod_info' + elem)).hasClass('disabled')) {
        $(this).parent().hide();
        $('#bloc_add_alert'+elem).show();
    } else { // On ajoute bien le produit en faisant les vérifications sur la taille et la couleur
        // On regarde si la personne a sélectionenr la taille et la couleur
        if (size_id !== '') {
            if (tailles.length && tailles.val() == "") {
                var txt_error = '<div class="productErrorFieldset" id="ligne_pointure_error'+elem+'">'+translate('quick_purchase_choose_a_size')+'</div>';
                btn.parent('.form_submit').removeClass('loading');
                $(txt_error).insertBefore($("#size_list"+elem));
            }
            var from = $('#btn_cmd_valid').length > 0 ? 'basket' : '';
            waitingAddToBasket(event, form, from, regroup_ref_id);
            setTimeout(function() {
                btn.parent('.form_submit').removeClass('loading');
            }, 2000);
        } else {

            if (size_id === '') {
                var txt_error = '<div class="productErrorFieldset" id="ligne_pointure_error'+elem+'">'+translate('choose_size')+'</div>';
            } else if (size_id === '') {
                var txt_error = '<div class="productErrorFieldset" id="ligne_pointure_error'+elem+'">'+translate('choose_size')+'</div>';
            }
            // Loader
            btn.parent('.form_submit').removeClass('loading');
            $(txt_error).insertBefore($("#size_list"+elem));  
        }
    }
}
/********reloadbloclogin********/
var reloadBlocLogin = function (evt) {

    var signIn = $('#auth');
    var passWd = $('#resetPass');
    var subLogin = $('#subtitleLogin');
    var subPassword = $('#subtitlePassword');

    evt.preventDefault();

    passWd.animate({
        opacity: 0
    }, 600, function () {

        passWd.css('display', 'none');

        signIn.css('display', '').animate({
            opacity: 1
        }, 600);
    });

    subPassword.animate({
        opacity: 0
    }, 600, function () {

        subPassword.css('display', 'none');

        subLogin.css('display', '').animate({
            opacity: 1
        }, 600);
    });
};

function dataLayer_associated_product () {

    if (window.$('#gondole_aimerez')) {

            var product_id = this.getAttribute('data-produit-id') ? event.currentTarget.getAttribute('data-produit-id') : '';
            var productObj = null;

            if (product_id !== '') {
                if (typeof data_layer_products != 'undefined' && data_layer_products.length > 0) {
                    data_layer_products.forEach(function (item) {
                        if (item.id == product_id) {
                            productObj = item;
                        }
                    })
                }
                if (typeof data_layer_products_aimerez != 'undefined' && data_layer_products_aimerez.length > 0) {
                    var objs = Object.keys(data_layer_products_aimerez);
                    var values = Object.values(data_layer_products_aimerez);

                    for (var i = 0; i < objs.length; i++) {
                        if (values[i].id == product_id) {
                            productObj = values[i];
                        }
                    }
                }

                if (typeof data_layer_products_look != 'undefined' && data_layer_products_look.length > 0) {
                    var objs = Object.keys(data_layer_products_look);
                    var values = Object.values(data_layer_products_look);

                    for (var i = 0; i < objs.length; i++) {
                        if (values[i].id == product_id) {
                            productObj = values[i];
                        }
                    }
                }
                var prod = productObj;

                if (productObj !== null && productObj !== undefined) {
                    dataLayer.push({
                        'event': 'productClick',
                        'ecommerce': {
                            'click': {
                                'actionField': {'list': productObj['category']},      // Optional list property.
                                'products': [prod]
                            }
                        },
                        'eventCallback': "function () {document.location = productObj.url}"
                    });

                    dataLayer.push({
                        'event': 'associatedProductClick',
                        'assoc_product': prod
                    });
                }
            }

    }
}

/***** FUNCTION PUSH INTO DATALAYER  *****/

function pushIntoDatalayer (data) {
    if (typeof(data) == 'object') {
        window.dataLayer.push(data);
    } else if (typeof(data) == 'string') {
        window.dataLayer.push(JSON.parse(data));
    }
}