// creer l'instanciation de l'objet XHR qu'inporte le navigateur
function getXMLHttpRequest() {
    var xhr = null;

    if (window.XMLHttpRequest || window.ActiveXObject) {
        if (window.ActiveXObject) {
            var tableau_xhr_activeX = [
                "Msxml2.XMLHTTP.6.0",
                "Msxml2.XMLHTTP.3.0",
                "Msxml2.XMLHTTP",
                "Microsoft.XMLHTTP",
            ];

            for (var value in tableau_xhr_activeX) {
                try {
                    xhr = new ActiveXObject(tableau_xhr_activeX[value]);
                    break;
                } catch (error) {}
            }
        } else {
            xhr = new XMLHttpRequest();
        }
    } else {
        return null;
    }
    return xhr;
}

// Permet le non rafraichissement d'une page en exploitant l'objet XHR
function ajax_file(fichier) {
    if (window.XMLHttpRequest)
        // FIREFOX-
        xhr_object = new XMLHttpRequest();
    else if (window.ActiveXObject)
        // IE
        xhr_object = new ActiveXObject("Microsoft.XMLHTTP");
    else return false;
    xhr_object.open("GET", fichier, false);
    xhr_object.send(null);
    if (xhr_object.readyState == 4) return xhr_object.responseText;
    else return false;
}

function ajax_file_post(url, params) {
    if (window.XMLHttpRequest)
        // FIREFOX-
        xhr_object = new XMLHttpRequest();
    else if (window.ActiveXObject)
        // IE
        xhr_object = new ActiveXObject("Microsoft.XMLHTTP");

    xhr_object.open("POST", url, false);
    xhr_object.setRequestHeader(
        "Content-type",
        "application/x-www-form-urlencoded"
    );
    xhr_object.send(params);

    if (xhr_object.readyState == 4 && xhr_object.status == 200) {
        return xhr_object.responseText;
    }
}

function ajax_file_XML(fichier) {
    if (window.XMLHttpRequest)
        // FIREFOX-
        xhr_object = new XMLHttpRequest();
    else if (window.ActiveXObject)
        // IE
        xhr_object = new ActiveXObject("Microsoft.XMLHTTP");
    else return false;
    xhr_object.open("GET", fichier, false);
    xhr_object.send(null);
    if (xhr_object.readyState == 4) return xhr_object.responseXML;
    else return false;
}

var global_id_transporteur = '';
function showNumRetourEtiquette(
    idOrder,
    multiTransp,
    parentPath,
    idOrderTransp,
    idTransporteur
) {

    // on définir globalement l'id transporteur pour l'utiliser dans la création de l'etiquette
    global_id_transporteur = idTransporteur;

    openMultiShad("popup_numero_retour");
}

function showPdfEtiquetteRetour(idOrder, multiTransp, numRetour, show_pdf) {
    if (!show_pdf) {
        data = $('#return_products_form').serialize();
        data += '&idOrder='+idOrder+'&multiTransp='+multiTransp+'&return_number='+numRetour + '&send_mail=true&choix=no_impression';
    } else {
        data = 'idOrder='+idOrder+'&multiTransp='+multiTransp+'&return_number='+numRetour;
    }

    $.ajax({
        url : path_relative_root + 'ajax_create_etiquette.php',
        type : 'post',
        data : data,
        success : function (res) {
            if ( res.substr(0,5) == 'false' ) {
                alert(Translator.translate('js_error_return_label'));
            } else {
                closeMultiShad('popup_numero_retour');
                if (show_pdf) {
                    window.open(res);
                } else {
                    location.reload();
                }
            }
        }
    });
}

function createEtiquetteRetour(idOrder, multiTransp) {

    var has_errors = false;
    $(".return_quantity").each(function() {
        var return_choice = $(this).closest('.js-select-motif').find('.return_choice');
        return_choice.removeClass('error');

        if ($(this).val() > 0  && return_choice.val() == '0') {
            has_errors = true;
            return_choice.addClass('error');
        }
    });

    if (!has_errors) {

        let data = $('#return_products_form').serialize();
        data += '&idOrder='+idOrder+'&multiTransp='+multiTransp;

        $.ajax({
            url : path_relative_root + 'ajax_create_etiquette.php',
            type : 'post',
            data : data,
            success : function (res) {
                location.reload();
            }
        });
    }
}

// --------------------------------------------------------------------------------

// Transformation XML en tableau
function traiteXmlQte(xmlDoc) {
    var options = xmlDoc.getElementsByTagName("option");
    var optionsListe = new Array();
    for (var i = 0; i < options.length; ++i) {
        var optionsListe2 = new Array();

        var id = options.item(i).getElementsByTagName("id");
        var label = options.item(i).getElementsByTagName("label");
        var color = options.item(i).getElementsByTagName("color");
        optionsListe2.push(
            id.item(0).firstChild.data,
            label.item(0).firstChild.data,
            color.item(0).firstChild.data
        );
        optionsListe.push(optionsListe2);
    }
    return optionsListe;
}

// Affiche la liste des quantites d'un produit (HTML Tag select)
function showQteListe(elementID, idProduit, parentPath, suff) {
    var couleurs_id = "couleurProd";
    var tailles_id = "tailleProd";

    if (elementID != "liste_qte") {
        couleurs_id += "_" + idProduit;
        tailles_id += "_" + idProduit;
    }

    var listQte = $("#" + elementID);
    var alternative_listQte = $("#liste_qte_spe");

    if (listQte.length || alternative_listQte.length) {
        if (listQte.length) {
            listQte.html(
                '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;margin-top:-4px;"><tr><td style="font-size:11px;color:#dedede" align="right"><img src="' +
                    parentPath +
                    'img/sablier_2.gif" style="margin-bottom:-6px;margin-right:10px;" />Chargement...</td></tr></table>'
            );

            var couleurs = $("#" + couleurs_id).length
                ? $("#" + couleurs_id).val()
                : "none";
            var tailles = $("#" + tailles_id).length
                ? $("#" + tailles_id).val()
                : "none";
        } else {
            alternative_listQte.html(listQte.html());

            var couleurs = "none";
            var tailles = "none";
        }

        var response = ajax_file(
            parentPath +
                "ajax_liste_qte.php?idprod=" +
                idProduit +
                "&idcouleur=" +
                couleurs +
                "&idtaille=" +
                tailles +
                "&produit_lot_nb=" +
                $("#produit_lot_nb").val() +
                "&suff=" +
                (suff || "")
        );

        if (response) {
            if (listQte.length) {
                listQte.html(response);
            } else {
                alternative_listQte.html(response);
            }

            var select_stock = $("#qteProd");
            var regexp_epuise = /Stock /;
            var txt_stock = $("#qteProd>option:selected").text();

            if (
                select_stock.length > 0 &&
                txt_stock.match(regexp_epuise) != null
            ) {
                $("#add_alert").show();
                $("#add_basket").hide();
            } else {
                $("#add_alert").hide();
                $("#add_basket").show();
            }
        }
    }
}

function showQteListeGamme(elementID, idProduit, parentPath) {
    var listQte = document.getElementById(elementID);
    var alternative_listQte = document.getElementById(
        "liste_qte_spe_" + idProduit
    );

    if (listQte || alternative_listQte) {
        if (listQte) {
            if (document.getElementById("couleurProd_" + idProduit))
                var couleurs = document.getElementById(
                    "couleurProd_" + idProduit
                ).value;
            if (document.getElementById("tailleProd_" + idProduit))
                var tailles = document.getElementById("tailleProd_" + idProduit)
                    .value;
            listQte.innerHTML =
                '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;"><tr><td style="font-size:11px;"><img src="' +
                parentPath +
                'img/sablier.gif" />Chargement...</td></tr></table>';
        } else {
            var couleurs = "none";
            var tailles = "none";
            alternative_listQte.innerHTML =
                '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;"><tr><td style="font-size:11px;"><img src="' +
                parentPath +
                'img/sablier.gif" />Chargement...</td></tr></table>';
        }

        var string_params = "";
        if ($("#produit_lot_nb_" + idProduit).val() != undefined)
            string_params +=
                "&produit_lot_nb=" + $("#produit_lot_nb_" + idProduit).val();

        if ($("#qte_default_" + idProduit).val() != undefined)
            string_params +=
                "&qte_default=" + $("#qte_default_" + idProduit).val();

        var response = ajax_file(
            parentPath +
                "ajax_liste_qte_alerte.php?idprod=" +
                idProduit +
                "&idcouleur=" +
                couleurs +
                "&idtaille=" +
                tailles +
                string_params
        );

        if (response) {
            if (listQte) listQte.innerHTML = response;
            else alternative_listQte.innerHTML = response;
        }
    }
}

function showQteListeAssoc(elementID, idProduit, parentPath) {
    var listQte = document.getElementById(elementID);
    var alternative_listQte = document.getElementById(
        "liste_qte_spe_" + idProduit
    );
    listQte.innerHTML =
        '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;"><tr><td style="font-size:11px;"><img src="' +
        parentPath +
        'img/sablier.gif" />Chargement...</td></tr></table>';
    var couleurs = document.getElementById("couleurProdAssoc_" + idProduit);
    var tailles = document.getElementById("tailleProdAssoc_" + idProduit);
    if (listQte || alternative_listQte) {
        if (listQte) {
            var couleurs = document.getElementById("couleurProd_" + idProduit)
                .value;
            var tailles = document.getElementById("tailleProd_" + idProduit)
                .value;
            listQte.innerHTML =
                '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;"><tr><td style="font-size:11px;"><img src="' +
                parentPath +
                'img/sablier.gif" />Chargement...</td></tr></table>';
        } else {
            var couleurs = "none";
            var tailles = "none";
            alternative_listQte.innerHTML =
                '<table border="0" cellpadding="0" cellspacing="0" style="padding-left:10px;"><tr><td style="font-size:11px;"><img src="' +
                parentPath +
                'img/sablier.gif" />Chargement...</td></tr></table>';
        }

        //if (couleurs.value != "" && tailles.value != "") {
        var response = ajax_file(
            parentPath +
                "ajax_liste_qte_alerte.php?idprod=" +
                idProduit +
                "&idcouleur=" +
                couleurs.value +
                "&idtaille=" +
                tailles.value
        );
        if (response) {
            // On ex�cute deux fois car replace ne remplace qu'une seule fois alors que nous avons deux occurences
            response = response.replace("qteProd_", "qteProdAssoc_");
            response = response.replace("qteProd_", "qteProdAssoc_");
            if (listQte) listQte.innerHTML = response;
            else alternative_listQte.innerHTML = response;
        }
        //}
        //else
    }
}

// Affiche la liste des auantites d'un produit (HTML Tag select)
function showQteListePanier(elementID, idProduit, i, paniervirtuelId) {
    var listQtePanier = document.getElementById(elementID);

    var couleurs = document.getElementById("couleurProd" + i);
    var tailles = document.getElementById("tailleProd" + i);

    var idCouleur = 0;
    var idTaille = 0;

    if (couleurs) idCouleur = couleurs.value;
    if (tailles) idTaille = tailles.value;

    if (listQtePanier) {
        var response = ajax_file(
            "ajax/ajax_liste_qte_panier.php?idprod=" +
                idProduit +
                "&idcouleur=" +
                idCouleur +
                "&idtaille=" +
                idTaille +
                "&i=" +
                i +
                "&panierId=" +
                paniervirtuelId
        );
        if (response) listQtePanier.innerHTML = response;
    }
}

function updatePanier(idProduit, i, paniervirtuelId) {
    var couleurs = document.getElementById("couleurProd" + i);
    var tailles = document.getElementById("tailleProd" + i);
    var qte = document.getElementById("qteProd" + i);

    var idCouleur = 0;
    var idTaille = 0;

    if (couleurs) idCouleur = couleurs.value;
    if (tailles) idTaille = tailles.value;

    ajax_file(
        "ajax/ajax_upd_panier.php?idprod=" +
            idProduit +
            "&idcouleur=" +
            idCouleur +
            "&idtaille=" +
            idTaille +
            "&qte=" +
            qte.value +
            "&panierId=" +
            paniervirtuelId
    );
}

//Affiche la liste des questions appropri�es au service demand�
function show_question(elementID, fieldID, idService, parentPath) {
    var list_question = document.getElementById(elementID),
        field = document.getElementById(fieldID);

    clearTimeout(time_out);

    if (list_question) {
        $.ajax({
            url: path_relative_root + create_link("ajax_list_question_contact"),
            type: "post",
            data: {idService: idService},
            success: function(res) {
                list_question.innerHTML = res;
                field.innerHTML = "";
            },
        });
    }
}

// Affiche les champs appropri�es au service et � la question demand�
function show_fields(elementID, idService, idQuestion, parentPath) {
    var list_field = document.getElementById(elementID);

    clearTimeout(time_out);

    if (list_field) {
        var response = ajax_file(
            parentPath +
                "ajax_field_contact.php?idService=" +
                idService +
                "&idQuestion=" +
                idQuestion
        );

        $(".contact-beware a").attr(
            "href",
            parentPath +
                create_link("connexion_login") +
                "?from=contact&id_service=" +
                idService +
                "&id_question=" +
                idQuestion
        );

        if (response) {
            list_field.innerHTML = response;

            $("#produit_ref_input").bind("keyup", function() {
                // Recuperation de tous les produit
                getAllProduct("produit_ref");
            });

            $("#produit_nom_input").bind("keyup", function() {
                // Recuperation de tous les produit
                getAllProduct("produit_nom");
            });

            tabError = [];

            tabError[tabError.length] = [
                "radio",
                "optin",
                "message",
            ];

            tabError[tabError.length] = [
                "alpha",
                "contactNom",
                "contact_field_nom",
            ];
            tabError[tabError.length] = [
                "alpha",
                "contactPrenom",
                "contact_field_prenom",
            ];
            tabError[tabError.length] = [
                "mail",
                "contactMail",
                "contact_field_mail",
            ];

            if (idService == 7) {
                tabError[tabError.length] = [
                    "alphanum",
                    "contactAdresse",
                    "contact_field_adresse",
                ];
                tabError[tabError.length] = [
                    "alphanum",
                    "contactCP",
                    "contact_field_cp",
                ];
                tabError[tabError.length] = [
                    "alphanum",
                    "contactVille",
                    "contact_field_ville",
                ];
                tabError[tabError.length] = [
                    "alphanum",
                    "contactPays",
                    "contact_field_pays",
                ];
            } else {
                if (idService == 2 && idQuestion == 5) {
                    tabError[tabError.length] = [
                        "alphanum",
                        "contactRefProduit",
                        "contact_field_ref_produit",
                    ];
                }

                if (idService == 14) {
                    if (idQuestion == 3) {
                        tabError[tabError.length] = [
                            "alphanumadresse",
                            "contactAdresse",
                            "contact_field_adresse",
                        ];
                        tabError[tabError.length] = [
                            "phone",
                            "contactNumPortable",
                            "contact_field_num_portable",
                        ];
                        tabError[tabError.length] = [
                            "alphanum",
                            "contactCP",
                            "contact_field_cp",
                        ];
                        tabError[tabError.length] = [
                            "alphanumadresse",
                            "contactVille",
                            "contact_field_ville",
                        ];
                        tabError[tabError.length] = [
                            "select_date",
                            "garantieDateAchat",
                            "date_achat",
                        ];
                        tabError[tabError.length] = [
                            "prix",
                            "contactPrix",
                            "prix",
                        ];
                        tabError[tabError.length] = [
                            "alphanum",
                            "id_produit_ref",
                            "date_achat",
                        ];

                        // Date Achat validation
                        $("#btn_contact").click(function(e) {
                            e.preventDefault();

                            if (
                                document.getElementById("garantieDateAchatM")
                                    .value === "" ||
                                document.getElementById("garantieDateAchatA")
                                    .value === "" ||
                                document.getElementById("garantieDateAchatJ")
                                    .value === ""
                            ) {
                                $("#garantieDateAchatLabel").addClass(
                                    "labelErr"
                                );
                            } else {
                                $("#garantieDateAchatLabel").removeClass(
                                    "labelErr"
                                );
                                $("#garantieDateAchatLabel")
                                    .siblings(".img_error")
                                    .remove();
                            }

                            if ($("#id_produit_ref").val() == "") {
                                $("#produit_ref_input").attr(
                                    "class",
                                    "inputErr"
                                );
                                $("#produit_nom_input").attr(
                                    "class",
                                    "inputErr"
                                );
                                $("#id_produit_refLabel").attr(
                                    "class",
                                    "labelErr"
                                );
                                $("#produit_nom_inputLabel").attr(
                                    "class",
                                    "labelErr"
                                );

                            } else {
                                $("#produit_ref_input").attr(
                                    "class",
                                    "inputForm"
                                );
                                $("#produit_nom_input").attr(
                                    "class",
                                    "inputForm"
                                );
                                $("#id_produit_refLabel").attr("class", "");
                                $("#produit_nom_inputLabel").attr("class", "");
                            }

                        });
                    } else if (idQuestion == 2) {
                        var content = ajax_file(
                            "ajax_field_active_garantie.php"
                        );

                        if (content) {
                            $("#select_garantieNomProduit").append(content);
                            $("#field_garantieNomProduit").removeClass("cache");
                            $("#field_error_garantieProduit").addClass("cache");
                            $("#field_photo").addClass("cache");
                            $("#field_error_garantieProduit td").empty();
                        } else {
                            $("#field_garantieNomProduit").addClass("cache");
                        }
                    }
                }
            }

            if (!(idService == 3 && idQuestion == 2) && idService != 7) {
                tabError[tabError.length] = [
                    "no_empty",
                    "contactMessage",
                    "contact_field_message",
                ];
            }
        } else {
            list_field.innerHTML = "";
        }
    }
}

function show_field_form(parentPath) {
    var list_field = document.getElementById("field");
    clearTimeout(time_out);
    if (list_field) {
        var response = ajax_file(
            parentPath + "ajax_field_contact.php?idService=7&idQuestion=1"
        );
        if (response) {
            list_field.innerHTML = response;

            tabError = new Array();
            tabError[0] = new Array("alpha", "contactNom", "contact_field_nom");
            tabError[1] = new Array(
                "alpha",
                "contactPrenom",
                "contact_field_prenom"
            );
            tabError[2] = new Array(
                "mail",
                "contactMail",
                "contact_field_mail"
            );
            tabError[3] = new Array(
                "alphanum",
                "contactAdresse",
                "contact_field_adresse"
            );
            tabError[4] = new Array(
                "alphanum",
                "contactCP",
                "contact_field_cp"
            );
            tabError[5] = new Array(
                "alphanum",
                "contactVille",
                "contact_field_ville"
            );
            tabError[6] = new Array(
                "alphanum",
                "contactPays",
                "contact_field_pays"
            );
        } else list_field.innerHTML = "";
    }
}

// Affiche les produits de la commande appropri�es au service litige // Contact
function show_product_commande(idCommande, parentPath) {
    var list_field = document.getElementById("field_produit_commande");
    if (idCommande) {
        var response = ajax_file(
            parentPath +
                "ajax_field_product_commande.php?idCommande=" +
                idCommande
        );
        if (response) {
            list_field.innerHTML = response;
            $("#produitCommande").removeClass("cache");
        }
    } else {
        list_field.innerHTML = "";
        $("#produitCommande").addClass("cache");
        $("#field_message").addClass("cache");
        $("#field_photo").addClass("cache");
        $("#field_send").addClass("cache");
    }
}

function paginationGoToPage(page, fromScrollBottom, fromScrollTop) {
    var page_bloc;

    page = page || 1;
    page_bloc = parseInt(page - 1);

    if (fromScrollBottom || fromScrollTop) {
        $(".loader_scroll").fadeTo("fast", 1);
    }

    // Déclaration de la variable let_it_scroll qui indique à generateNewBlocProd de scrollTop lors de cet appel (ex changement de page) car sinon elle vient récupérer la valeur du formulaire de filtre qui empêche justement de scroller lors du filtrage
    var let_it_scroll = true;

    // Si on est sur la page de recherche
    if ($('#search_page').length > 0) {
        generateNewBlocSearch(page_bloc, null, null, null, null, fromScrollBottom, fromScrollTop);
    } else {

        generateNewBlocProd(page_bloc, null, null, null, null, fromScrollBottom, fromScrollTop, null, let_it_scroll);
    }
}

function paginate(page) {
    var form = document.getElementById('filter_sticky');

    if (form != null) {
        var total_element = form.getValue('totalElems', parseFloat),
        nb_prod = form.getValue('nb_prod', parseFloat),
        nb_total_page = Math.ceil(total_element / nb_prod),
        pagination = $('.pager_wrapper'),
        nb_prod_default = form.getValue('nb_prod_default', parseFloat),
        see_all = form.getValue('see_all', parseFloat),
        page = page || 1;
    }


    $.ajax({
        url: path_relative_root + create_link("ajax_reload_pagination"),
        type: 'get',
        data: {
            page: page,
            link: 'paginationGoToPage()',
            nb_total_page: nb_total_page
        },
        success: function (res) {
            if (pagination !== undefined && pagination.length) {
                pagination.each(function () {
                    if (see_all === 0 && nb_prod < total_element) {
                        this.innerHTML = '<div class="see_all_product" onclick="seeXProduct('+ total_element +', ' + page +')">' + Translator.translate('see_all_prod', total_element) + '</div>' + res;
                    } else if (total_element > nb_prod_default) {
                        this.innerHTML = '<div class="see_all_product" onclick="seeXProduct(' + nb_prod_default + ', 0)">' + Translator.translate('see_x_product_by_page', nb_prod_default) + '</div>';
                    } else {
                        this.innerHTML = '';
                    }
                });
            }
        }
    });
}

function seeXProduct(nb_product, page) {
    var form = document.getElementById('filter_sticky'),
        nb_prod_default = form.getValue('nb_prod_default', parseFloat);
        // Déclaration de la variable let_it_scroll qui indique à generateNewBlocProd de scrollTop lors de cet appel (ex changement de page) car sinon elle vient récupérer la valeur du formulaire de filtre qui empêche justement de scroller lors du filtrage
        let_it_scroll = true;
        $('#scroll_origin').val(page);

    //Pour afficher tout, on repart de la page courante
    if (nb_product === (nb_prod_default + 1)) {
        //On force à la valeur initiale pour conserver l'organisation de la page
        nb_product = nb_prod_default;
        $("#see_all").val("1");
        generateNewBlocProd((page - 1), null, null, null, null, null, null, isFrom, let_it_scroll);
        $("#initial_page").val(page);

    } else {
        $("#see_all").val("0");
        $("#nb_prod").val(nb_product);
        $("#initial_page").val(1);
        // Si on est sur la page de recherche
        if ($("#search_page").length > 0) {
            generateNewBlocSearch(0);
        } else {
            generateNewBlocProd(0);
        }
    }
}

/**
 * Pour charger tous les produits sur une page
 * @author
 * @since
 *
 * @param  String           type       [description]
 * @param  Number           idObj      [description]
 * @param  Number           page       [description]
 * @param  Number           nb_product [description]
 * @param  HTMLElement      btn        le bouton sur lequel on a cliqué pour charger les element (si undefined pas de probleme)
 *
 * @return void
 */

var bloc_prod_xhr;

function onColorCheckChange(evt) {
    if (this.checked && !evt.ctrlKey) {
        $(this.closest(".selected_options"))
            .find("input[type=checkbox]:checked")
            .not(this)
            .prop("checked", false);
        //
    }
}

//Uilisée pour afficher les produits
function generateNewBlocProd(page, type, idObj, nb_product, btn, fromScrollBottom, fromScrollTop, isFrom, let_it_scroll) {
    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filter_sticky'),
        ctrl = Array.prototype.slice.call(form.elements, 0),
        page = (page !== undefined) ? page : form.getValue('page', parseFloat),
        type = type || form.getValue('type'),
        idObj = idObj || form.getValue('idObj'),
        nb_product = nb_product || form.getValue('nb_prod', parseFloat),
        output = {},
        is_from_page = false,
        key;
        let_it_scroll = (let_it_scroll == undefined) ? form.getValue('let_it_scroll', parseFloat) : let_it_scroll;
        if (let_it_scroll == undefined) {
            let_it_scroll == true;
        }

    //Ajout pour le scroll infini - empeche le chargement d'une autre page tant qu'on n'a pas terminé l'opération en cours
    if (fromScrollTop || fromScrollBottom) {
        $('#is_loading').val('1');
    }

    //Modification de la position du loader selon fromScrollTop/Bottom
    if (fromScrollBottom) {
        var fromTop = $('.pager_wrapper').offset().top + 10;
        $('.loader_scroll').css('top', fromTop);
    } else if (fromScrollTop) {
        var fromTop = $('#scroll_items').offset().top - 10;
        $('.loader_scroll').css('top', fromTop);
    };

    output = triggerFilter(page, type, idObj, nb_product, 'filter_sticky');

    if (page === 'all') {

        /** LEGACY **/

        var hash;

        if ((hash = location.hash && location.hash.match(/i=[0-9]+/g)[0])) {

            var marginTop;
            if ($('.wrapper_bandeau')) {
                margintop = $('.wrapper_bandeau').height() + 20
            } else {
                margintop = $('#site_head_wrap').height() + 20
            }
            var $elem = hash ? $('#i=' + hash.replace('=', '')) : $list_item,
                offset = $elem.offset(),
                top = offset && offset.top - marginTop;

            window.setTimeout(function () {

                $('#contents_wrapper').animate({
                    scrollTop: top
                }, 0.8);
            }, 0);
        }

        /** END LEGACY **/
    } else {

        if (bloc_prod_xhr) {

            bloc_prod_xhr.abort();
        }

        bloc_prod_xhr = $.ajax({
            url: path_relative_root + create_link("ajax_reload_products"),
            type: 'get',
            data: output,
            success: function (res) {
                var old_elm = document.getElementById('list_item'),
                content, itm_length;

                if (btn && btn.nextElementSibling && btn.nextElementSibling.classList.contains('btn_tunnel')) {

                    btn.nextElementSibling.style.display = 'none';
                }

                res = res.split('><><');
                content = res[0].trim();
                itm_length = parseFloat(res[1]);

                let canonical = res[5];
                let prev = res[6];
                let next = res[7];

                $("link[rel^='canonical']").attr("href", path_root + canonical);
                $("link[rel^='alternate'][hreflang^='fr']").attr("href", path_root + canonical);

                $("link[rel^='alternate'][hreflang^='en']").attr("href", JSON.parse(res[8]).en);

                let hrefPrev = $("link[rel^='prev']");
                let hrefNext = $("link[rel^='next']");
                let commentPrev = $('#href_prev');
                let commentNext = $('#href_next');

                changeSeoElement(prev, hrefPrev, commentPrev, 'prev');
                changeSeoElement(next, hrefNext, commentNext, 'next');

                if (content !== '') {

                    if (!isNaN(page)) {
                        old_elm.setAttribute('data-p', (page * nb_product));
                    }

                    if (fromScrollBottom || fromScrollTop) {
                        $(".loader_scroll").fadeTo("fast", 0);
                    } else {
                        old_elm.innerHTML = "";
                    }

                    document.getElementById('totalElems').value = itm_length;
                    var filter_submit = document.querySelector('#rayon_sticky_block .holder_submits .form_submit .button');
                    filter_submit.classList.add('to_submit');
                    filter_submit.querySelector('span').innerHTML = Translator.translate('see_x_results', itm_length);
                    paginate(page + 1);

                    //Si on était en haut de page, on rajoute en haut
                    if (fromScrollTop) {
                        var firstMsg = $('#scroll_items .item:first');
                        var curOffset = firstMsg.offset().top - $(document).scrollTop();

                        // Sur Stellaforest : Si on charge une page en scrollTop, on vient récupérer les éléments du bandeau afin de les ajouter dans la liste de produit avant que ce dernier ne soit mis à jour via la fonciton appendToBandeau() -> D'abord le produit, ensuite le push, ensuite les produits de la requête, afin de suive la logique d'apparition
                        old_elm.insertAdjacentHTML('afterbegin', $('#bandeau_rayon .holder_topProduct .item').get(0).outerHTML);
                        old_elm.insertAdjacentHTML('afterbegin', $('#bandeau_rayon #push_rayon_3').get(0).outerHTML);
                        old_elm.insertAdjacentHTML('afterbegin', content);

                        // Si on scroll vers le haut et que la dernière page à charger est la première, on cache le loader_scroll si il est visible
                        if (page == 0) {
                            $('.loader_scroll').hide();
                        }

                        $(document).scrollTop(firstMsg.offset().top - curOffset);

                        //L'inverse pour le bas
                    } else if (fromScrollBottom) {
                        old_elm.insertAdjacentHTML('beforeend', content);
                    } else {
                        old_elm.insertAdjacentHTML('beforeend', content);
                    }

                    if (window.lazyload !== undefined) {
                        lazyload.init();
                    }

                    /** LEGACY **/

                    var js_compteur = $('.vf_countdown').length;
                    var js_encart = content.match(/\/\/vf_begin([\s]+[\w$(){}\[\]=;\0,\'\".:\/<&!+% ]*)+\/\/vf_end/g);

                    if (js_compteur != null) {
                        try {
                            var cnt_compteur = js_compteur.length;

                            for (var i = 0; i < cnt_compteur; i++) {
                                eval(js_compteur[i].replace(/(vf_compteur_begin|vf_compteur_end)/g, ""));
                            }
                        } catch (e) { }
                    }
                    if (js_encart != null) {
                        try {
                            js_encart = js_encart[0].replace(/(\/\/vf_begin|\/\/vf_end)/g, "");
                            eval(js_encart);
                        } catch (e) { }
                    }

                    /** END LEGACY **/
                } else {
                    content = '<div class="no_results_wrapper">' +
                        '<div class="search_details_wrapper">' +
                        '<div class="no_result_block">' +
                        Translator.translate('no_result_txt') +
                        '</div>' +
                        '</div>' +
                        '</div>';
                    old_elm.innerHTML = content;
                    $('.items_found .nb').html(0);
                    old_elm.appendChild(new_elm);
                }

            },
            complete: function () {

                //force trigger on input hidden to fire the change() event
                $('#totalElems').trigger('change');
                $('#bottom_reached').val("0").trigger('change');
                $('#page').trigger('change');

                //Si on recharge la page, on recentre sur le nouvel affichage
                if (!fromScrollBottom && !fromScrollTop) {
                    if ($('.item').length <= (nb_product + 1)) {
                        // Scroll to top of page to see first item in bandeau
                        if (let_it_scroll) {
                            window.scrollTo(0, ($('.wrapper_bandeau').offset().top));
                        }
                    } else if (isFrom != 1) {
                        if (let_it_scroll) {
                            setTimeout(function () {
                                $("html, body").animate({
                                    scrollTop: $('.wrapper_bandeau').offset().top
                                }, 200);
                            }, 250);
                        }
                        //reset initiialPage if from filter
                        $("#initial_page").trigger("change");
                    } else if (isFrom === 1) {
                        //On recharge la page sur le dernier élément vu si on part sur voir tout
                        window.scrollTo(0, (($('#scroll_items').offset().top + $('#scroll_items').height())) - ($('.item').height() + 150));
                    }
                }

                //Maintenant que tout est chargé, on débloque pour les autres pages s'il y en a
                if (fromScrollTop || fromScrollBottom) {
                    $('#is_loading').val('0');
                }

                //On met à jour la valeur
                if (fromScrollBottom) {
                    $("#initial_page").val(page + 1);
                }
            }
        });
    }
}

function updateFilter(
    tab_response,
    page,
    parentPath,
    type,
    idObj,
    no_price_range_update
) {
    if (tab_response[0] != "") {
        var bloc_photo = tab_response[0];
        document.getElementById("list_item").innerHTML = bloc_photo;

        $(".slider_product_wrapper").each(function() {
            $(this).hover(
                function() {
                    $(this)
                        .children(".visu_product_slider")
                        .children(".visu_product_slider_hover")
                        .show();
                },
                function() {
                    $(this)
                        .children(".visu_product_slider")
                        .children(".visu_product_slider_hover")
                        .hide();
                }
            );
        });

        var nav = tab_response[2];
        var nav_bas = tab_response[3];

        var nb_prod = tab_response[4];

        if (document.getElementById("nb_prod_numeric"))
            document.getElementById("nb_prod_numeric").innerHTML = nb_prod;
        if (document.getElementById("label_nb_prod_numeric")) {
            if (nb_prod == "")
                document.getElementById("label_nb_prod_numeric").style.display =
                    "none";
            else
                document.getElementById("label_nb_prod_numeric").style.display =
                    "block";
        }

        if (typeof page == "undefined") {
            var prix_min = tab_response[5];
            var prix_max = tab_response[6];

            if (!no_price_range_update) {
                $("#amount1").val(prix_min);
                $("#amount2").val(prix_max);
                $("#slider-range").slider("option", {
                    min: parseInt(prix_min),
                    max: parseInt(prix_max),
                    values: [parseInt(prix_min), parseInt(prix_max)],
                });
                min_price = prix_min;
                max_price = prix_max;

                $(".ui-slider-range").width("100%");
                $(".ui-slider-range").css("left", "0%");
                $(".ui-slider-range")
                    .next()
                    .css("left", "0%");
                $(".ui-slider-range")
                    .next()
                    .next()
                    .css("left", "100%");
            }

            // couleur
            var color = tab_response[7];
            var tab_color_tmp = color.split("~");
            var cnt_color = tab_color_tmp.length;

            if (tab_color_tmp[0] != "") {
                txt_color = Translator.translate('recherche_couleurs');
                var bloc_color =
                    '<div class="select_recherche">' +
                    txt_color +
                    '</div><div class="selected_options select_colors"></div>';

                var str_color = "";

                for (var j = 0; j < cnt_color; j++) {
                    var detail_color = tab_color_tmp[j].split("|");
                    var class_color = "btn_select_off";

                    if (tab_color.length > 0) {
                        if (in_array_js(detail_color[0], tab_color)) {
                            class_color = "btn_select";
                        }
                    }
                    str_color +=
                        '<div id="couleur_' +
                        detail_color[0] +
                        '" class="left_menu_obj ' +
                        class_color +
                        " couleur_" +
                        detail_color[0] +
                        '"><div class="f_left couleur_check"></div><div class="f_left txt_menu">' +
                        detail_color[1] + "</div></div>";
                }

                $("#bloc_couleur")
                    .show()
                    .find(".selected_options")
                    .html(str_color);
            } else {
                $("#bloc_couleur")
                    .hide()
                    .find(".selected_options")
                    .html("");
            }
            // size
            var size = tab_response[8];

            var tab_size_tmp = size.split("~");
            var cnt_size = tab_size_tmp.length;

            if (tab_size_tmp[0] != "") {
                txt_size = Translator.translate('recherche_taille');

                var bloc_size =
                    '<div class="select_recherche">' +
                    txt_size +
                    '</div><div class="selected_options"></div>';

                var str_size = "";

                for (var j = 0; j < cnt_size; j++) {
                    var detail_size = tab_size_tmp[j].split("|");
                    var class_size = "btn_select_off";

                    if (tab_taille.length > 0) {
                        if (in_array_js(detail_size[0], tab_taille))
                            class_size = "btn_select";
                    }

                    str_size +=
                        '<div id="taille_' +
                        detail_size[0] +
                        '" class="left_menu_obj ' +
                        class_size +
                        '"><div class="f_left"></div><div class="f_left txt_menu">' +
                        detail_size[1] +
                        "</div></div>";
                }

                $("#bloc_taille")
                    .show()
                    .find(".selected_options")
                    .html(str_size);
                var windowW = $(window).height();
                if (windowW >= 900) {
                    $("#choix_recherche").sideBarEase("refresh");
                }
            } else {
                $("#bloc_taille")
                    .hide()
                    .find(".selected_options")
                    .html("");
            }
        }
    } else {
        $("#list_item").html(Translator.translate('no_response_search'));
        $("#pagination_haut").html("");
        $("#pagination_bas").html("");
    }
    $(".left_menu_obj").on("click touchend", filterBoxesToggle);
    var windowW = $(window).height();
    if (windowW >= 900) {
        $("#choix_recherche").sideBarEase("refresh");
    }
    $("#page").val(0);
}

//On va regarde tout les bloc et on envoi l'ajax
//Pour les blocs de cases � cocher
//Pour le slider des prix
//Pour le bouton qui supprime un element
var bloc_search_xhr;

function generateNewBlocSearch(page, type, idObj, nb_product, btn, fromScrollBottom, fromScrollTop) {
    var form = (this instanceof HTMLFormElement) ? this : document.getElementById("filter_sticky"),
        output = {},
        is_from_page = false,
        type = type || form.getValue('type'),
        idObj = idObj || form.getValue('idObj'),
        ctrl = Array.prototype.slice.call(form.elements, 0),
        page = (page !== undefined) ? page : form.getValue('page', parseFloat),
        nb_product = nb_product || form.getValue('nb_prod', parseFloat),
        is_from_page = false,
        key;

    Array.prototype.slice.call(form.elements).forEach(function(elm) {
        if (elm.type === "checkbox" || elm.type === "radio") {
            if (elm.checked && elm.value !== "") {
                if (output[elm.name] === undefined) {
                    output[elm.name] = [];
                }

                output[elm.name].push(elm.value);
            }
        } else if (elm.type === "hidden") {
            if (elm.value !== "") {
                output[elm.name] = elm.value;
            }
        }
    });

    for (key in output) {
        if (output.hasOwnProperty(key)) {
            if (output[key].constructor === Array) {
                output[key] = output[key].join("~");
            }
        }
    }

    if (nb_product !== undefined) {
        form.nb_prod.value = nb_product;
    } else {
        nb_product = form.nb_prod.value;
    }

    if (page !== undefined && page !== "all") {
        form.page.value = page * nb_product;
        is_from_page = true;

    }

    // Add misc values to output object
    output.page = form.page.value;
    output.parentPath = path_relative_root;
    output.nbProducts = nb_product;
    output.is_page = is_from_page;
    output.keyword = form.keyword.value;
    output.nb_prod = form.nb_prod.value;
    output.nb_prod_default = form.nb_prod_default.value;
    output.totalElems = form.totalElems.value;

    // Si le prix existe
    if ($("#price_min").length > 0) {
        // Récupération du prix
        output.prix =
            form.getValue("price_min", parseFloat) +
            "~" +
            form.getValue("price_max", parseFloat);
    }

    if (bloc_search_xhr) {
        bloc_search_xhr.abort();
    }

    bloc_search_xhr = $.ajax({
        url: path_relative_root + create_link("ajax_reload_search"),
        type: "get",
        data: output,
        success: function(res) {
            var old_elm = document.getElementById("scroll_items"),
                content,
                itm_length;

            if (
                btn &&
                btn.nextElementSibling &&
                btn.nextElementSibling.classList.contains("btn_tunnel")
            ) {
                btn.nextElementSibling.style.display = "none";
            }

            res = res.split("><><");
            content = res[0].trim();

            size = res[8].trim();

            var array_size = size.split("~");

            itm_length = parseFloat(res[1]);

            if (content !== "") {
                if (!isNaN(page)) {
                    old_elm.setAttribute("data-p", page * nb_product);
                }

                old_elm.innerHTML = "";

                document.getElementById("nb_articles").innerHTML = itm_length;
                document.getElementById("totalElems").value = itm_length;

                paginate(page + 1);

                if (fromScrollTop) {
                    var firstMsg = $('#scroll_items .item:first');
                    var curOffset = firstMsg.offset().top - $(document).scrollTop();
                    old_elm.insertAdjacentHTML('afterbegin', content);
                    $(document).scrollTop(firstMsg.offset().top-curOffset);

                } else {
                    old_elm.insertAdjacentHTML('beforeend', content);
                }

                if (window.lazyload !== undefined) {
                    lazyload.init();
                }

                /** LEGACY **/

                var js_compteur = $(".vf_countdown").length;
                var js_encart = content.match(
                    /\/\/vf_begin([\s]+[\w$(){}\[\]=;\0,\'\".:\/<&!+% ]*)+\/\/vf_end/g
                );

                if (js_compteur != null) {
                    try {
                        var cnt_compteur = js_compteur.length;

                        for (var i = 0; i < cnt_compteur; i++) {
                            eval(
                                js_compteur[i].replace(
                                    /(vf_compteur_begin|vf_compteur_end)/g,
                                    ""
                                )
                            );
                        }
                    } catch (e) {}
                }
                if (js_encart != null) {
                    try {
                        js_encart = js_encart[0].replace(
                            /(\/\/vf_begin|\/\/vf_end)/g,
                            ""
                        );
                        eval(js_encart);
                    } catch (e) {}
                }

                /** END LEGACY **/
                fix_rayon_display();
            }
        },
        complete: function() {
            //force trigger on input hidden to fire the change() event
            $('#totalElems').trigger('change');
            $('#bottom_reached').val("0").trigger('change');
            $('#page').trigger('change');
            if (!fromScrollBottom && !fromScrollTop) {
                setTimeout(function () {
                    $("html, body").animate({
                        scrollTop: 0
                    }, 200);
                }, 250);
                //reset initiialPage if from filter
                $("#initial_page").trigger("change");
            }

            $('.category #site_global_wrap').addClass('fullyLoaded');
        },
    });
}

// Affiche la liste des commandes selon le type
function show_orders_by_type(elementID, type, parentPath, reload) {

    var str_year = '';
    var str_reload = '';
    var year = $("#year").val();

    if (year != '') {

        str_year = '&year=' + year;
    }

    if (reload !== undefined) {

        str_reload = '&reload=true';
    }

    //@ Add underline on commamde onglet
    $(".wrapper_mes_commandes .wrapper_menu_tab #com_onglet .onglet").removeClass("actif");
    $(".wrapper_mes_commandes .wrapper_menu_tab #com_onglet #com_onglet_" + type).addClass("actif");


    var container = document.getElementById(elementID);

    if (container) {
        var response = ajax_file(parentPath + 'ajax_account_orders_by_type.php?type=' + type + str_year + str_reload);

        if (response) {
            if (reload !== undefined) {
                $('.content_commandes').html(response);
            } else {

                $(container).html(response);
            }
            return true;
        }
        else {
            container.innerHTML = '';
            return false;
        }
    }
    else
        return false;
}

/**
 * Gestion de l'ouverture et de la fermeture des messages dans mon comptes (mes-message.php)
 */
function openCloseMessage(elem, msg_open, msg_close) {
    var id = elem.attr("id"),
        mId = id.substring(id.indexOf("_") + 1),
        messageContainer = $(".message_account_container");

    if (messageContainer.hasClass("appear")) {
        messageContainer.removeClass("appear");
    } else {
        messageContainer.addClass("appear");
    }

    if (!elem.hasClass("actif")) {
        $(".message_detail").slideUp();
        $(".btn_ouvrir_message")
            .not(elem)
            .removeClass("actif");
        $(".btn_ouvrir_message")
            .not(elem)
            .text(msg_open);

        if ($("#msg_txt_" + mId).html() == "") {
            $.ajax({
                type: "post",
                data: {mId: mId, type: 1},
                url: path_relative_root + create_link("message_detail"),
                success: function(response) {
                    $("#msg_txt_" + mId).html(response);
                    $("#msg_" + mId).slideDown();
                },
            });
        } else $("#msg_" + mId).slideDown();
        elem.addClass("actif");
        elem.text(msg_close);
    } else {
        $(".message_detail").slideUp();
        elem.removeClass("actif");
        elem.text(msg_open);
    }
}
function show_message(mId, hide) {
    if (hide == 0) {
        var ajax_content = ajax_file("ajax_show_messages.php?mId=" + mId);
        if (ajax_content) {
            document.getElementById("message_" + mId).innerHTML = ajax_content;

            document.getElementById("message_" + mId).onclick = "";
            document.getElementById("message_" + mId).style.cursor = "default";
        }
    } else {
        var ajax_content = ajax_file(
            "ajax_show_messages.php?mId=" + mId + "&hide=1"
        );
        if (ajax_content) {
            document.getElementById("message_" + mId).innerHTML = ajax_content;

            document.getElementById("message_" + mId).onclick =
                "show_message(" + mId + ",0);";

            document.getElementById("message_" + mId).style.cursor = "pointer";
        }
    }
}

var timer;
// --------------------------------------------------------------------------------
function newNewsletterFromCmpid() {
    var flag = verif_mail("email_inscription_home", "login_email");

    if (flag) {
        flag = ajax_file(
            path_relative_root +
                "ajax_email_newsletter_exist.php?mail=" +
                document.getElementById("email_inscription_home").value
        );

        var elementID = "email_inscription_home";

        var objElement = document.getElementById(elementID);
        var objLabel = document.getElementById(elementID + "Label");
        var erreurText = document.getElementById("erreur_msg");

        if (flag === "1") {
            flag = false;
        } else {
            flag = true;
        }

        if (!flag) {
            erreurText.innerHTML =
                '<p class="title_toolltip">' + Translator.translate('login_email') +
                " :</p>" +
                "Cet email est déjà inscrit à la newsletter";
            objElement.className = "inputErr";
            objLabel.className = "labelErr";
        } else {
            objElement.className = "inputForm";
            objElement.style.border = "1px solid #CCCCCC";
            objLabel.className = "labelForm";
        }
    }

    if (flag) {
        clearTimeout(timer);

        document.getElementById("bloc_erreur").style.display = "none";
        document.getElementById("img_error_popup").style.display = "none";
    } else {
        var val = document.getElementById("erreur_msg").innerHTML;
        var elem_img = document.getElementById("img_error_popup");
        $("#img_error_popup").css("display", "inline-block");

        elem_img.setAttribute("onmouseover", function() {
            OverPopup(this, val, "visible", "cmpid_adwords");
        });
        elem_img.onmouseover = function() {
            OverPopup(this, val, "visible", "cmpid_adwords");
        };
        elem_img.setAttribute("onmouseout", function() {
            OverPopup(this, val, "none", "cmpid_adwords");
        });
        elem_img.onmouseout = function() {
            OverPopup(this, val, "none", "cmpid_adwords");
        };

        elem = document.getElementById("email_inscription_home");

        elem.style.border = "1px solid red";
        pos = getPos(elem_img);

        document.getElementById("bloc_erreur").style.position = "absolute";

        var nav = navigator.appVersion;

        if (!/*@cc_on!@*/ (false || !!document.documentMode)) {
            $("#bloc_erreur").fadeIn(400);
        } else {
            document.getElementById("bloc_erreur").style.display = "block";
        }

        var clone = $("#bloc_erreur").clone(),
            c_pos = {
                top:
                    $("#img_error_popup").offset().top -
                    $("#img_error_popup")
                        .offsetParent()
                        .offset().top -
                    $("#bloc_erreur").height() +
                    4,
                left:
                    $("#img_error_popup").offset().left -
                    $("#img_error_popup")
                        .offsetParent()
                        .offset().left -
                    $("#bloc_erreur").innerWidth() +
                    $("#img_error_popup").width() * 2 +
                    6,
            };

        $("#bloc_erreur").remove();
        $("#img_error_popup").after(clone);

        $("#bloc_erreur")
            .find("td:nth-child(2)")
            .children("img")
            .css({
                float: "right",
                margin: "0 7px",
            });

        $("#bloc_erreur").css({
            position: "absolute",
            top: c_pos.top,
            left: c_pos.left + 271,
            "z-index": "99999",
            opacity: "1",
        });

        window.clearTimeout(timer);

        timer = setTimeout("closeInfobulle('bloc_erreur')", 5000);
    }

    return flag;
}

function checkAndAddMail() {
    if (newNewsletterFromCmpid()) {
        $("#btn_conf_cmpid").css("display", "none");
        $("#loader_popup").css("display", "block");

        response = ajax_file(
            path_relative_root +
                "ajax_add_email_newsletter.php?mail=" +
                document.getElementById("email_inscription_home").value +
                "&from=lightbox"
        );

        if (response == 1) {
            $("#formu_inscription_news").fadeOut(750, function() {
                $("#conf_inscription_news").fadeIn(750);
            });

            document.getElementById("shad").setAttribute("onclick", function() {
                closeMultiShad("popup_inscription_home");
            });
            document.getElementById("shad").onclick = function() {
                closeMultiShad("popup_inscription_home");
            };
        }
    }
}

function ajaxDeconnect(path_relative_root) {
    var provenance = window.location.pathname,
        cpt_client = new RegExp(
            /(?:adresses|mes_infos|newsletter|parrainage|mon-compte|facture-liste|avoir-liste|commande_detail|panier|livraison-kiala|livraison|paiement|banque|erreur_cmd|erreur_commande|banque_refus|remerciements|endereco|newsletter-pt|conta|conta-lista|haver-lista|encomenda_detalhes|cesta|remessa|pagamento|banco|erreur_cmd_pt|erros-encomenda|banco-recusa|agradecimento)/
        ),
        response;

    // Get the current page's name
    provenance = provenance.substr(
        provenance.lastIndexOf("/"),
        provenance.lastIndexOf(".")
    );

    // This actually disconnects the client
    response = ajax_file(path_relative_root + "ajax_deconnect.php");

    // If there was an error
    if (!response) alert("Erreur lors de la déconnexion.");
    // Else if the current page can't be displayed when not logged in: redirect
    else if (cpt_client.test(provenance))
        window.location.href =
            path_relative_root + create_link("connexion_login");
    // Else: refresh
    else window.location.reload();

    // If the page hasn't been reloaded: remove the loader and return false
    hideLoader();

    return false;
}

/** wishlist **/
function addToWishlistShoppingcart() {
    $.post(path_relative_root + create_link("ajax_cart_to_wishlist"), function(resp) {
        $(".btn_top_wishlist .gris").html(
            "(x" + resp.result.wishlist_size + ")"
        );
        $("#addToWishlistShoppingcart")
            .removeClass("addToWishlistShoppingcart")
            .addClass("existsToWishlistShoppingcart");
        $("#addToWishlistShoppingcart").html(resp.result.button_label);
        $("#addToWishlistShoppingcart")
            .attr("onclick", "")
            .attr(
                "href",
                path_relative_root + create_link("client_index") + "#wl"
            );
    });
}

function deletePromoCode() {
    $.ajax({
        type: "get",
        url: path_relative_root + create_link("delete_promo_code"),
        success: function(response) {
            location.href = path_relative_root + create_link("order_kiala");
        },
    });
}

function ajax_RemoveBasketProd(basket_id, prod_id, qte) {
    $.ajax({
        type: "post",
        data: {
            basket_id: basket_id,
            prod_id: prod_id,
            qte: qte,
        },
        url: path_relative_root + create_link("ajax_removeBasketProd"),
        success: function(res) {
            try {
                res = $.parseJSON(res);
            } catch (e) {
                console.error("parseJSON");

                return;
            }

            $("#cart_top").html(res.datas.html);
            $('#cart_top #show_top_cart').addClass('show');

            var count_bask = $('#cart_top .cart').attr('data-cart');

            if (count_bask > 2) {
                $("#cart_top #cart_list").overlayScrollbars({});
                $("#cart_top  #show_top_cart").overlayScrollbars({});
            }

            if ($('.prod_reassurance_swiper').length) {
                setTimeout(function () {
                    var rolloverCartReassuranceSwiper = new Swiper('#show_top_cart .prod_reassurance_swiper', {
                        // slidesPerView: 2.5,
                        slidesPerView: 'auto',
                        loop: true,
                        speed: 4000,
                        freeMode: true,
                        autoplay: {
                            delay: 1,
                            disableOnInteraction: false,
                        },
                    });
                }, 500);
            }
        },
    });
}

function sendMessage(id) {
    //disable the default form submission

    var mId = id;
    $(".loader_reponse_contact").css("display", "block");
    $("#envoyer_rep").addClass("active");

    if ($("#msg_txt_" + id).val() != "") {
        var upload_fail = Translator.translate('contact_msg_pj_fail');
        var formData = new FormData($("form#data")[0]);
        $.ajax({
            url: path_relative_root + create_link("message_detail"),
            type: "POST",
            data: formData,
            async: false,
            cache: false,
            contentType: false,
            processData: false,
            success: function(response) {
                if (response == "1") {
                    $("#msg_conf_" + id).html(Translator.translate('contact_msg_envoyer'));
                    $("#msg_conf_" + id).show();
                    $("#msg_conf_" + id).addClass("active");
                    $("#msg_txt_" + id).val(" ");
                    clearInput("filepj1");
                    $(".loader_reponse_contact").css("display", "none");
                    $("#envoyer_rep").addClass("active");
                    return false;
                } else if (response == "3") {
                    clearInput("filepj1");
                } else {
                    $("#msg_conf_" + mId).html(Translator.translate('contact_msg_fail'));
                    $("#msg_conf_" + mId).show();
                }
            },
        });
    } else {
        $(".loader_reponse_contact").css("display", "none");
        $("#contact_msg_text_vide")
            .css("color", "red")
            .fadeIn();
        setTimeout(function() {
            formMessageReset(id);
        }, 5000);
        $("#msg_conf_" + mId).hide();
    }
}

function ajax_achat_express(reference, wishlist, idprod, code_couleur) {
    var arg = '';
    if (wishlist !== undefined) {
        arg = '&wishlist=true';
    }
    var self = $(this);
    $.ajax({
        type: 'get',
        url: path_relative_root + 'ajax_achat_express.php?ref=' + reference + '&couleur=' + code_couleur + arg,
        success: function (response) {
            var $lighbox = $('#lightbox_achat_express');
            var $shad = $('#shad');
            if (!$lighbox.length) {
                $('body').append('<div id="lightbox_achat_express"><div><w-alertestock-form ref="wAlertestockForm" id="bloc_add_alert_form" email=""; ?>" type-alert="" style="display:none"></w-alertestock-form></div></div>');
                $lighbox = $('#lightbox_achat_express');
            }
            $lighbox
                .html('<div class="express_response"><button class="close_pop" onclick="closeMultiShad(\'lightbox_achat_express\')">' + '<span>X</span>' + '</button>' + response + '</div>')
                .appendTo('body');
            $lighbox.append('<input id="achat_express_lightbox" type="hidden" name="achat_express_lightbox" value="1" />');

            openMultiShad('lightbox_achat_express');
            showQteListe('liste_qte_range', idprod, path_relative_root);

            // Pour les event des tooltips "Veuillez selectionner une taille"
            eventButton();

            $('#lightbox_achat_express #bloc_add_alert #btn_add_alert').click(function () {
                $('#lightbox_achat_express #bloc_add_alert .btn_container').hide();
            });

            $('.bloc_add_alert_confirmation button').click(function() {
                $('#bloc_add_alert .btn_container').show();
            });

            if (!$(".w-alertestock-form").length) {
                new Vue({components:{wAlertestockForm: wAlertestockForm}}).$mount('#keep_alive_stock');
            }

            if ( $('.product_infos_form.not_achat_express #product_selects') ) {
                main_product_select_html = $('.product_infos_form.not_achat_express #product_selects').html();
                $('.product_infos_form.not_achat_express #product_selects').html('');
            }

            setTimeout(function () {
                checkSubmitState();
            }, 500);
        }
    });
}


function process_type_preco() {
    var val = $(".bloc_lightbox_preco.actif").attr("data-value");

    $.ajax({
        type: "post",
        data: {type_preco: val},
        url: path_relative_root + create_link("ajax_choose_preco"),
        success: function(response) {
            if (response.status == "success") {
                if ($("input[name=preco_choice]").length == 0) {
                    closeMultiShad("lightbox_preco");
                }
                var index_conveyor =
                    typeof array_transporteurs_datas[1] != "undefined" ? 1 : 31;

                $.each(response.transportation, function(i, item) {
                    array_transporteurs_datas[i]["fdp"][0] = item["unit"];
                    array_transporteurs_datas[i]["fdp"][1] = item["cent"];
                    array_transporteurs_datas[i]["fdp"]["format"] =
                        item["format"];
                    $(
                        ".choix_delivery[data-id-transporteur=" +
                            i +
                            "] .pricetag"
                    ).html(item["format"]);
                    $(
                        ".choix_delivery[data-id-transporteur=" +
                            i +
                            "] .price_tag"
                    ).html(item["format"]);
                });

                if (val == "several") {
                    $(".choix_delivery_relais").hide();
                    $(".choix_delivery_postal:first .choix_delivery").click();
                    $(".choix_delivery_postal:first .choix_livraison").attr(
                        "checked",
                        "checked"
                    );
                    $(".choix_delivery_postal:first .choix_livraison").change();
                } else {
                    $(".choix_delivery_relais").show();
                    $(".choix_delivery_relais:first").click();
                    $(".choix_delivery_relais:first .choix_livraison").attr(
                        "checked",
                        "checked"
                    );
                    $(".choix_delivery_relais:first .choix_livraison").change();
                }

                $("#tunnel_right_col #cart_total").css("visibility", "visible");
            }
        },
    });
}

function viewByTwo() {
    if ($('#list_item').length && $('#list_item').hasClass('bydefault')) {
        $('#list_item').removeClass('bydefault');
        $('#list_item').addClass('byTwo');
        $('#viewChanger .byTwo').addClass('actif');
        $('#viewChanger .default').removeClass('actif');
        createCookie("nb_produits", 3, 1);
        // create cookie when view is switched to two and create cookie view_bytwo
        createCookie("view_bytwo", true, 1);
    }

    setTimeout(function () {
        $('.productSwiper').each(function () {
            if ($(this).hasClass('swiper-container-initialized')) {
                this.swiper.update();
            }
        });
    }, 600);
}

function viewDefault() {
    if ($('#list_item').length && $('#list_item').hasClass('byTwo')) {
        $('#list_item').removeClass('byTwo');
        $('#list_item').addClass('bydefault');
        $('#viewChanger .byTwo').removeClass('actif');
        $('#viewChanger .default').addClass('actif');
        createCookie("nb_produits", 4, 1);
        // set cookie view_bytwo to false on switch to default view by 4
        createCookie("view_bytwo", false, 1);
    }

    setTimeout(function () {
        $('.productSwiper').each(function () {
            if ($(this).hasClass('swiper-container-initialized')) {
                this.swiper.update();
            }
        });
    }, 400);
}

function filterTrigger() {
    if ($('#rayon_sticky_block').length) {
        var trigger = $('#trigger_filtre');
        var filter = $('#rayon_sticky_block');
        var shad = $('#shad_link');

        if (!trigger.hasClass('triggered')) {
            trigger.addClass('triggered');
            filter.addClass('show');
            shad.addClass('actif filterOn');

            $("html, body").animate({
                scrollTop: $('#rayon_sticky_block').offset().top - 93,
            }, 500);

        } else if (trigger.hasClass('triggered')) {
            closeFiltre();
        }

        if (trigger.hasClass('triggered')) {
            var shad_filtre = $('#shad_link.filterOn');
            shad_filtre.on("click touch", function() {
                closeFiltre();
            });
        }

        function closeFiltre() {
            if (trigger.hasClass('triggered')) {
                trigger.removeClass('triggered');
                filter.removeClass('show');
                shad.removeClass('actif filterOn');
            }
        }
    }
}

var tempo_reset = false;

function initAllFilters() {

    var form = (this instanceof HTMLFormElement) ? this : document.getElementById('filter_sticky'),
        ctrl = Array.prototype.slice.call(form.elements, 0);

    ctrl.forEach(function (elm) {

        if ((elm.type === 'checkbox' || elm.type === 'radio')) {

            if (elm.checked && elm.name !== 'sscat' && elm.value !== '') {
                elm.value = 0;
                elm.checked = false;
            }
        }
    });

    $('a.tri_price.actif').removeClass('actif');
    $('input#choice_tri').val(0);

    tempo_reset = true;
    generateNewBlocProd.call(form, 0);

    setTimeout(function() {
        // Wrap up filters when filters reset
        displayDropdownFilters(1);
    }, 500);

}

// Linked to achat express
function newUpdatePanier(idProduit, i, paniervirtuelId, prixU, path_web, titreObjet, idElTaille, lastTaille, idElCouleur, lastCouleur, form) {

    if (i != '') {

        i = '_' + i;

        prixU = $('#prixU' + i).val();
    }

    var couleurs = $(form).find('#couleurProd' + i);
    var tailles = $(form).find('#tailleProd' + i);
    var res = '';
    var html_err = '';
    var flagError = false;

    if ($('#lightbox_achat_express').length > 0) {

        from_range = true;
    } else {

        from_range = false;
    }

    html_err = '<div class="txt_alert"></div>';
    html_err += '<div class="wrapper_btn"><a onclick="closeMultiShad();" href="#" class="btn_alert">OK</a></div>';

    if (tailles.length && tailles.val() == '') {

        $('#sizeerror').slideDown();

        if ($('#product_addcart .form_submit').hasClass('loading')) {
            $('#product_addcart .form_submit').removeClass('loading');
        }

        return false;
    } else if (couleurs && couleurs.val() == '') {

        res = Translator.translate('js_error_couleur');
        flagError = true;
    } else if (document.getElementById('qteProd' + i) && document.getElementById('qteProd' + i).value == '') {

        res = Translator.translate('js_error_quantity');
        flagError = true;
    }

    if (flagError) {

        alert(res);

        return false;
    } else {

        var qte = 0;

        if ($('#qteProd' + i)) {

            qte = $('#qteProd' + i).val();
        }

        var idCouleur = 0;
        var idTaille = 0;

        if (couleurs && couleurs.val() != 'none')
            idCouleur = couleurs.val();

        if (tailles && tailles.val() != 'none')
            idTaille = tailles.val();

        var array_response = [];
        var response = ajax_file(path_web + 'ajax_upd_panier.php?prixu=' + prixU + '&idprod=' + idProduit + '&idcouleur=' + idCouleur + '&idtaille=' + idTaille + '&qte=' + qte + '&panierId=' + paniervirtuelId);

        if (response) {

            array_response = response.split("//");

            var designation = array_response[0];

            if (titreObjet.match('^[0-9]')) {

                if (designation == 1) {

                    designation += ' lot de';

                } else {

                    designation += ' lots de';

                }

            }

            designation += ' ' + titreObjet;

            if (document.getElementById(idElTaille)) {

                if (document.getElementById(idElTaille).selectedIndex) {

                    designation += ' - Taille ' + document.getElementById(idElTaille).options[document.getElementById(idElTaille).selectedIndex].text;

                } else if (lastTaille != '') {

                    designation += ' - Taille ' + lastTaille;

                }

            }

            if ($('[name="' + idElCouleur + '"]').length > 0) {

                if ($('[name="' + idElCouleur + '"]:checked').length > 0) {

                    designation += ' - ' + $('[name="' + idElCouleur + '"]:checked').get(0).nextElementSibling.title.trim();
                } else if (lastCouleur != '') {

                    designation += ' - ' + lastCouleur;
                }
            }

            array_response[0] = designation;

            // SI il n'y a plus de stock
            if (array_response[1] == 'false') {
                $('#size_' + idTaille).addClass('disabled');
                alert(Translator.translate('product_no_stock_2'));
                return false;
            }

            var is_achat_express = $('#is_achat_express').val();
            if (array_response.length >= 0 && array_response[5] !== undefined) {
                var dl_objet = array_response[5];
                if (is_achat_express !== undefined && is_achat_express == 1) {
                    var new_dl_objet = JSON.parse(dl_objet)
                    new_dl_objet.ecommerce.is_achat_express = true;
                    dl_objet = JSON.stringify(new_dl_objet);
                }
                pushIntoDatalayer(dl_objet);
            }
        }

        return array_response;
    }
}

function remove_product_from_wishlist(id, callback) {
    $.post(path_relative_root + create_link("ajax_remove_from_wishlist"), { id: id }, callback);
}

function ajax_RemoveWishlistProd(id) {
    remove_product_from_wishlist(id, function(data) {
        if (data.result) {
            var wishlistLength = data.result.wishlistLength;

            addToWishlistUpdateHeader(wishlistLength);

            $('.remove_top_wishlist[data-id=' + id + ']').parents('.product-link').remove();
            if (wishlistLength == 0) {
                $('#wishlist_top #show_top_cart #cart_list, #wishlist_top  #show_top_cart .wrap_tot_panier').remove();
                $('<div class="no_product">' + Translator.translate('your_wishlist_empty') + '</div>').insertAfter('#wishlist_top  #show_top_cart .rollover_title');
            } else {
                $("#wishlist_top #show_top_cart").addClass('show');
                $('#wishlist_top  #show_top_cart .cart_panier_total .price').html(data.result.wishlistSum);
            }
        }
    });
}

if ($('.remove_top_wishlist').length) {
    $(document).on("click touch",".remove_top_wishlist", function (ev) {
        var id = $(this).attr("data-id");
        var current_elem = $(this)

        $.post(path_relative_root+"ajax_remove_from_wishlist.php", {id: id}, function(data) {

            var nr;
            if (data.result == undefined) {
                data.result = $('.wish_liste_product .product_ctn').length;

            } else {
                nr = data.result.wishlistLength;
            }

            if (nr == 0) {
                $("#wishlist_top .cart_box").html('');
                $("#show_top_cart").addClass('hide');
                topCartDesappear();
            }

            $("#wishlist_top .nb_products").html(nr);
            $("#wishlist_top .nbr_item").html(nr);

            var count_bask = $('#wishlist_top .cart').attr('data-cart');

            if (count_bask > 3) {
                $("#wishlist_top #cart_list").overlayScrollbars({});
            }

            $(current_elem.parents('.product-link')).fadeOut(100, function() {
                current_elem.parents('.product-link').remove();
            });

        });
    });
}

if ($('#btn_top_add_wishlist').length || $('#move_wish_panier').length) {
    $(document).on("click","#btn_top_add_wishlist, #move_wish_panier", function (e) {
        e.preventDefault();
        selectedProducts = [];
        var origin = $(this).attr("id");

        if (origin === 'btn_top_add_wishlist') {
            $(".remove_top_wishlist").each(function( index ) {
                var id = $(this).attr("data-id");
                selectedProducts.push(id);
            });
        } else if (origin === 'move_wish_panier') {
            var id = $(this).attr("data-id");
            selectedProducts.push(id);
        }
        moveProductFromWishlistToCart(selectedProducts, origin, id);
    });
}

if ($('#faqTunnelList').length) {
    $(document).on("click",".faq_tunnel_question", function () {
        $('.faq_tunnel_question').not($(this)).next('.faq_tunnel_answer').slideUp();
        $('.faq_tunnel_question').not($(this)).removeClass('actif');

        if ($(this).hasClass('actif')) {
            $(this).removeClass('actif');
            $(this).next('.faq_tunnel_answer').slideUp();
        } else {
            $(this).addClass('actif');
            $(this).next('.faq_tunnel_answer').slideDown();
        }
    });
}

function moveProductFromWishlistToCart(array_wishlist_product_ids, origin, id) {
    $.post(path_relative_root + create_link("ajax_add_panier_wishlist"), {data: array_wishlist_product_ids.join(",")}, function(resp) {

        if (resp == "ok" && origin !== 'move_wish_panier') {
            document.location.href = path_relative_root + create_link('order_basket');
        } else if (resp == "ok" && origin === 'move_wish_panier') {
            ajax_RemoveWishlistProd(id);
        }
    });
}

function createCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toGMTString();
    }
    document.cookie = name + "=" + value + expires + "; path=/";
}

function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1, c.length);
        }
        if (c.indexOf(nameEQ) == 0) {
            return c.substring(nameEQ.length, c.length);
        }
    }
    return null;
}

$(document).ready(function () {   
    // to prevent view to change after switched from default to two and vice versa
    if (readCookie("view_bytwo") === 'true') {
        createCookie('nb_produits', 3, 1);
    } else {
        createCookie('nb_produits', 4, 1);
    }
    // View x4
    if (readCookie("nb_produits") == 4) {
        if ($('#list_item').length && $('#list_item').hasClass('byTwo')) {
            $('#list_item')
                .removeClass('byTwo')
                .addClass('bydefault');
            $('#viewChanger .byTwo').removeClass('actif');
            $('#viewChanger .default').addClass('actif');
        }

    } else {
        // Default view x3
        if ($('#list_item').length && $('#list_item').hasClass('bydefault')) {
            $('#list_item')
                .removeClass('bydefault')
                .addClass('byTwo');
            $('#viewChanger .default').removeClass('actif');
            $('#viewChanger .byTwo').addClass('actif');
        }
    }
});

// Utilisé pour afficher la lightbox - CP produit spécifique offert
function display_lightbox_specifique() {

    var html = ajax_file(path_relative_root + create_link("ajax_lightboxproduitspecifique"));
    if (html) {
        var $lighbox = $('#lightbox_achat_express');
        var $shad = $('#shad');
        $lighbox
            .html('<div style="position: relative">' + html + '</div>')
            .appendTo('body')
            .show();
        $shad
            .show()
            .unbind('click.achat_express')
            .bind('click.achat_express', function () {
                closeMultiShad('shad');
                $lighbox.hide();
                $("#alertbox").remove();
            });
    }
}

/**
 * Permet de calculer les mensualités d'un paiement Cofidis 3x
 * @param {string} amount
 */
function calculCofidis3Cb()
{
    var amount = $('#montantCalcul').val();
    if (amount > 0) {
        $.ajax({
            url : 'https://www.simulateurcofidis.com/3cb/index.php',
            type : 'get',
            data : {'montant': amount, 'key': 'RlX3lf1f98Cal6r0v1ul7Phq7YfJSdnybnvuXuL6T9RaVGhRyr', 'defaut': 0, 'retour': 'flux'},
            success : function (response) {
                var resJson = JSON.parse(response);
                var montant = Number.parseFloat(resJson.montant).toFixed(2).toString().replace('.', ',');
                var mensualite = Number.parseFloat(resJson.mensualite).toFixed(2).toString().replace('.', ',');
                var derniere_mensulatie = Number.parseFloat(resJson.derniere_mensulatie).toFixed(2).toString().replace('.', ',');
                var montant_du = Number.parseFloat(resJson.montant_du).toFixed(2).toString().replace('.', ',');
                $('#tab_mensualites .montant .tab_amount span').html(montant);
                $('#tab_mensualites .mensualite_1 .tab_amount span').html(mensualite);
                $('#tab_mensualites .mensualite_2 .tab_amount span').html(derniere_mensulatie);
                $('#tab_mensualites .montant_du .tab_amount span').html(montant_du);
                $('#tab_mensualites .frais .tab_amount span').html(resJson.frais);
            }
        });
    }
}